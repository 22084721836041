import moment from 'moment-mini'
import {
  COMMON_DEAL_STATUS_NAMES,
  DEAL_STATUS_LABEL,
  DEAL_STATUS_NAME,
  FORM_TYPE_LABELS,
  FORM_TYPE_NAMES
} from '@/constants/P2P'
import Decimal from 'decimal.js'
import P2PDealAddressModel from '@/models/P2P/P2PDealAddressModel'
import Store from '@/store'
import ChatMessageModel from '@/models/ChatMessageModel'

export default class {
  constructor (data, origData) {
    const {
      buy_user_id,
      sell_user_id,
      operation_type,
      payment_reason,
      country,
      city,
      buy_amount,
      sell_amount,
      buy_user_security,
      sell_user_security,
      payment_types,
      operation_id,
      offer_operation_id,
      match_operation_id,
      statuses,
      timestamp,
      requisites,
      rule_type,
      addresses,
      address_hashes,
      accepted_address_hash,
      accepted_payment_type,
      banknotes,
      agreement_docs,
      act_docs,
      accepted_agreement,
      accepted_act,
      buy_product_details,
      buy_requisites,
      sell_product_details,
      sell_requisites,
      sell_offer_operation_id,
      buy_user_comment,
      sell_user_comment,
      arbitration,
      sell_offer,
      buy_offer,
    } = data || {}
    this.orig = origData
    this.arbitration = arbitration
    this.sell_offer_operation_id = sell_offer_operation_id
    this.buy_product_details = buy_product_details
    this.buy_requisites = buy_requisites
    this.sell_product_details = sell_product_details
    this.sell_requisites = sell_requisites
    this.agreement_docs = agreement_docs || []
    this.act_docs = act_docs || []
    this.accepted_agreement = accepted_agreement
    this.accepted_act = accepted_act
    this.operation_id = operation_id
    this.offer_operation_id = offer_operation_id
    this.timestamp = timestamp
    this.country = country
    this.city = city
    this.buy_user_id = buy_user_id
    this.sell_user_id = sell_user_id
    this.operation_type = operation_type
    this.payment_reason = payment_reason
    this.buy_amount = buy_amount
    this.sell_amount = sell_amount
    this.buy_user_security = buy_user_security || {}
    this.sell_user_security = sell_user_security || {}
    this.buy_user_comment = buy_user_comment
    this.sell_user_comment = sell_user_comment
    this.payment_types = payment_types
    this.match_operation_id = match_operation_id
    this.statuses = statuses
    this.requisites = requisites
    this.banknotes = banknotes
    this._time = moment.unix(this.timestamp).format('DD/MM/YY HH:mm:ss')

    this.buyAmount = this.buy_amount.amount
    this.buyCurrencyShort = Store.state.tokensNameMap[this.buy_amount.currency]?.code
    this.sellAmount = this.sell_amount.amount
    this.sellCurrencyShort = Store.state.tokensNameMap[this.sell_amount.currency]?.code
    this.statusLabel = DEAL_STATUS_LABEL[!!arbitration?.user_id ? 'ARBITRATION' : this.statuses.at(-1)]
    this.typeLabel = FORM_TYPE_LABELS[this.operation_type]
    this.rule_type = rule_type
    this.addresses = addresses?.map(i => new P2PDealAddressModel(i)) || []
    this.address_hashes = address_hashes
    this.accepted_address_hash = accepted_address_hash
    this.accepted_payment_type = accepted_payment_type
    this.isSellingExternalFiat = this.buy_amount.currency.includes('RUB')
    this.sell_offer = sell_offer
    this.buy_offer = buy_offer
    if (this.isSellingExternalFiat) {
      this.fiatBuyAmount = this.buyAmount
      this.fiatBuyCurrency = this.buyCurrencyShort
      this.fiatSellAmount = this.sellAmount
      this.fiatSellCurrency = this.sellCurrencyShort
      this.buyUserComment = this.sell_user_comment
      this.sellUserComment = this.buy_user_comment
      this.buyer = this.sell_user_id
      this.seller = this.buy_user_id
      this.buyerReserveAmount = this.sell_user_security.amount
      this.buyerReserveCurrency = Store.state.tokensNameMap[this.sell_user_security.currency]?.code
      this.sellerReserveAmount = this.buy_user_security.amount
      this.sellerReserveCurrency = Store.state.tokensNameMap[this.buy_user_security.currency]?.code
      this.buyProductDetails = this.buy_product_details
      this.buyRequisites = this.buy_requisites
      this.sellProductDetails = this.sell_product_details
      this.sellRequisites = this.sell_requisites
    } else {
      this.fiatBuyAmount = this.sellAmount
      this.fiatBuyCurrency = this.sellCurrencyShort
      this.fiatSellAmount = this.buyAmount
      this.fiatSellCurrency = this.buyCurrencyShort
      this.buyUserComment = this.buy_user_comment
      this.sellUserComment = this.sell_user_comment
      this.buyer = this.buy_user_id
      this.seller = this.sell_user_id
      this.buyerReserveAmount = this.buy_user_security.amount
      this.buyerReserveCurrency = Store.state.tokensNameMap[this.buy_user_security.currency]?.code
      this.sellerReserveAmount = this.sell_user_security.amount
      this.sellerReserveCurrency = Store.state.tokensNameMap[this.sell_user_security.currency]?.code
      this.buyProductDetails = this.sell_product_details
      this.buyRequisites = this.sell_requisites
      this.sellProductDetails = this.buy_product_details
      this.sellRequisites = this.buy_requisites
    }
    this.exchange_rate = new Decimal(this.fiatBuyAmount).div(this.fiatSellAmount).toFixed()
    this._rateCurrency = `${this.fiatSellCurrency}/${this.fiatBuyCurrency}`
    this.messages = [
      ...this.addresses.map(i => {
        return ChatMessageModel('address',i)
      }),
      ...this.agreement_docs.map(i => {
        return ChatMessageModel('AgencyAgreement', i)
      }),
      ...this.act_docs.map(i => {
        return ChatMessageModel('Act', i)
      })
    ].filter(Boolean)
  }
  get canceled () {
    return this.statuses.length === 2 && this.statuses[0] === DEAL_STATUS_NAME.OPEN && this.statuses[1] === DEAL_STATUS_NAME.CLOSED
  }
  get lastStatus () {
    return this.statuses.at(-1)
  }
  get sortedAgreementDocs () {
    return this.agreement_docs.sort((a, b) => a.timestamp - b.timestamp)
  }
  get sortedActDocs () {
    return this.act_docs.sort((a, b) => a.timestamp - b.timestamp)
  }
  get isArbitration () {
    return !!this.arbitration?.user_id
  }

  get isClosed () {
    return this.lastStatus === COMMON_DEAL_STATUS_NAMES.CLOSED
  }
}
