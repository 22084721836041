import {
  CURRENCIES_WEY_UNITS,
  LION_TOKEN_ADDRESS,
  minABIForTokens,
  NETWORK_VERSION,
  USDT_TOKEN_ADDRESS
} from '@/constants/input'

const CURRENCIES_CONFIG = {
  USDT: {
    address: USDT_TOKEN_ADDRESS,
    weiUnit: CURRENCIES_WEY_UNITS.USDT
  },
  LION: {
    address: LION_TOKEN_ADDRESS,
    weiUnit: CURRENCIES_WEY_UNITS.LION
  }
}

export default class {
  constructor (currency, web3Service) {
    this.currency = currency
    this.web3Service = web3Service
  }

  get network() {
    return this.web3Service.ethNetwork
  }

  get contract() {
    const { address } = CURRENCIES_CONFIG[this.currency]
    return new this.network.Contract(minABIForTokens, address)
  }

  async transfer(toAddress, amount) {
    const { currentProvider } = this.network
    const amountWithoutDot = amount.replace('.', '')
    if (currentProvider?.networkVersion !== NETWORK_VERSION) throw new Error('Invalid network ID')
    else return this.contract.methods.transfer(toAddress,amountWithoutDot).send({
      from: currentProvider.selectedAddress
    })
  }
}
