import { FORM_TYPE_NAMES, PAYMENT_TYPE_NAMES, SELECTOR_VALUE_NAMES } from '@/constants/P2P'
import { deepCopy } from '@/utils/common'
import Decimal from 'decimal.js'
import PizZip from 'pizzip'
import Docxtemplater from 'docxtemplater'
import { saveAs } from "file-saver";

export const makeP2PDeals = (orders, decimalPlacesFa, decimalPlacesSa) => {
  const _orders = deepCopy(orders)
  let iterCount = 0
  const dealAction = (marketMaker, marketTaker) => {
    const rateForDeal = marketMaker.exchange_rate

    const saVolume = Decimal.min(marketMaker.second_amount, marketTaker.second_amount)

    marketMaker.second_amount = new Decimal(marketMaker.second_amount).minus(saVolume).toFixed(decimalPlacesSa, Decimal.ROUND_DOWN)
    marketMaker.first_amount = new Decimal(marketMaker.second_amount).div(rateForDeal).toFixed(decimalPlacesFa, Decimal.ROUND_DOWN)

    marketTaker.second_amount = new Decimal(marketTaker.second_amount).minus(saVolume).toFixed(decimalPlacesSa, Decimal.ROUND_DOWN)
    marketTaker.first_amount = new Decimal(marketTaker.second_amount).div(marketTaker.exchange_rate).toFixed(decimalPlacesFa, Decimal.ROUND_DOWN)

    const minMathAmountProp = marketMaker.first_currency.includes('Cash') ? 'first_amount' : 'second_amount'

    if (new Decimal(marketMaker[minMathAmountProp]).lt(marketMaker.min_match_amount)) {
      marketMaker.first_amount = 0
      marketMaker.second_amount = 0
    }
    if (new Decimal(marketTaker[minMathAmountProp]).lt(marketTaker.min_match_amount)) {
      marketTaker.first_amount = 0
      marketTaker.second_amount = 0
    }
  }
  _orders.forEach((currentOrder, orderIdx) => {
    iterCount++
    const isSell = currentOrder.type === 'sell'
    if (!isSell) {
      currentOrder.first_amount = new Decimal(currentOrder.second_amount).div(currentOrder.exchange_rate).toFixed(decimalPlacesFa, Decimal.ROUND_DOWN)
    }
    const typeForSearch = isSell ? 'buy' : 'sell'
    const amountPropForSearch  = typeForSearch === 'sell' ? 'first_amount' : 'second_amount'
    const tempOrders = _orders.slice(0, orderIdx).filter(i => new Decimal(i[amountPropForSearch]).gt(0))
    const ordersForMatch = tempOrders.filter(i => {
      iterCount++
      return i.type === typeForSearch
    })
    ordersForMatch.sort((a, b) => {
      iterCount++
      const minuend = isSell ? b : a
      const subtrahend = isSell ? a : b
      return new Decimal(minuend.exchange_rate || 0).minus(subtrahend.exchange_rate || 0).toNumber()
    })
    for (let orderForDeal of ordersForMatch) {
      iterCount++
      if (
        (currentOrder.type === 'sell' && new Decimal(currentOrder.first_amount).eq(0)) ||
        (currentOrder.type === 'buy' && new Decimal(currentOrder.second_amount).eq(0))
      ) break
      // варианта с ошибочными заявками, т.е. курс может не совпадать, но матчиться должно
      const operator = currentOrder.type === 'sell' ? 'lte' : 'gte'
      // вариант с точным совпадением курсов
      // const operator = 'eq'
      const needP2PMatch = checkTypes(currentOrder, orderForDeal)
      if (!needP2PMatch) continue
      if (
        currentOrder.response_offer_op_id === orderForDeal.operation_id ||
        (!currentOrder.response_offer_op_id && new Decimal(currentOrder.exchange_rate)[operator](orderForDeal.exchange_rate))
      ) {
        dealAction(orderForDeal, currentOrder)
      }
    }
    if (currentOrder.response_offer_op_id) {
      currentOrder.first_amount = 0
      currentOrder.second_amount = 0
    }
  })
  return _orders.filter(i => !new Decimal(i.first_amount).eq(0))
}

export const makeP2PBillDeals = (orders, decimalPlacesFa, decimalPlacesSa) => {
  const _orders = deepCopy(orders)
  let iterCount = 0
  const dealAction = (marketMaker, marketTaker) => {
    const rateForDeal = marketMaker.exchange_rate

    const saVolume = Decimal.min(marketMaker.second_amount, marketTaker.second_amount)

    marketMaker.second_amount = new Decimal(marketMaker.second_amount).minus(saVolume).toFixed(decimalPlacesSa, Decimal.ROUND_DOWN)
    marketMaker.first_amount = new Decimal(marketMaker.second_amount).div(rateForDeal).toFixed(decimalPlacesFa, Decimal.ROUND_DOWN)

    marketTaker.second_amount = new Decimal(marketTaker.second_amount).minus(saVolume).toFixed(decimalPlacesSa, Decimal.ROUND_DOWN)
    marketTaker.first_amount = new Decimal(marketTaker.second_amount).div(marketTaker.exchange_rate).toFixed(decimalPlacesFa, Decimal.ROUND_DOWN)

    const minMathAmountProp = marketMaker.first_currency.includes('Cash') ? 'first_amount' : 'second_amount'

    if (new Decimal(marketMaker[minMathAmountProp]).lt(marketMaker.min_match_amount)) {
      marketMaker.first_amount = 0
      marketMaker.second_amount = 0
    }
    if (new Decimal(marketTaker[minMathAmountProp]).lt(marketTaker.min_match_amount)) {
      marketTaker.first_amount = 0
      marketTaker.second_amount = 0
    }
  }
  _orders.forEach((currentOrder, orderIdx) => {
    iterCount++
    const isSell = currentOrder.type === 'sell'
    if (!isSell) {
      currentOrder.first_amount = new Decimal(currentOrder.second_amount).div(currentOrder.exchange_rate).toFixed(decimalPlacesFa, Decimal.ROUND_DOWN)
    }
    const typeForSearch = isSell ? 'buy' : 'sell'
    const amountPropForSearch  = typeForSearch === 'sell' ? 'first_amount' : 'second_amount'
    const tempOrders = _orders.slice(0, orderIdx).filter(i => new Decimal(i[amountPropForSearch]).gt(0))
    const ordersForMatch = tempOrders.filter(i => {
      iterCount++
      return i.type === typeForSearch && i.operation_id === currentOrder.response_offer_op_id
    })
    ordersForMatch.sort((a, b) => {
      iterCount++
      const minuend = isSell ? b : a
      const subtrahend = isSell ? a : b
      return new Decimal(minuend.exchange_rate || 0).minus(subtrahend.exchange_rate || 0).toNumber()
    })
    for (let orderForDeal of ordersForMatch) {
      iterCount++
      if (
        (currentOrder.type === 'sell' && new Decimal(currentOrder.first_amount).eq(0)) ||
        (currentOrder.type === 'buy' && new Decimal(currentOrder.second_amount).eq(0))
      ) break
      dealAction(orderForDeal, currentOrder)
    }
    if (currentOrder.response_offer_op_id) {
      currentOrder.first_amount = 0
      currentOrder.second_amount = 0
    }
  })
  return _orders.filter(i => !new Decimal(i.first_amount).eq(0))
}

export const getCounteroffer = (newOffer, offers) => {
  const ordersForCheck = offers.filter(i => i.type !== newOffer.type),
    conditions = [checkExchangeRate, isMKTUNumbersMatch]
  return ordersForCheck.find(order => {
    return conditions.every(condition => condition(newOffer, order))
  })
}

export const makeP2PAssetDeals = (orders) => {
  const _orders = deepCopy(orders)
  let iterCount = 0
  const dealAction = (marketMaker, marketTaker) => {
    const quantityVolume = Decimal.min(marketMaker.asset.quantity, marketTaker.asset.quantity)

    marketMaker.asset.quantity = new Decimal(marketMaker.asset.quantity).minus(quantityVolume).toNumber()

    marketTaker.asset.quantity = new Decimal(marketTaker.asset.quantity).minus(quantityVolume).toNumber()

    if (new Decimal(marketMaker.asset.quantity).lt(marketMaker.min_match_amount)) {
      marketMaker.asset.quantity = 0
    }
    if (new Decimal(marketTaker.asset.quantity).lt(marketTaker.min_match_amount)) {
      marketTaker.asset.quantity = 0
    }
  }
  _orders.forEach((currentOrder, orderIdx) => {
    iterCount++
    const isSell = currentOrder.type === 'sell'
    const typeForSearch = isSell ? 'buy' : 'sell'
    const tempOrders = _orders.slice(0, orderIdx).filter(i => new Decimal(i.asset.quantity).gt(0))
    const ordersForMatch = tempOrders.filter(i => {
      iterCount++
      return i.type === typeForSearch
    })
    for (let orderForDeal of ordersForMatch) {
      iterCount++
      if (new Decimal(currentOrder.asset.quantity).eq(0)) break
      const needP2PMatch = checkTypes(currentOrder, orderForDeal)
      if (!needP2PMatch) continue
      if (
        currentOrder.response_offer_op_id === orderForDeal.operation_id ||
        (
          !currentOrder.response_offer_op_id &&
          currentOrder.asset.name === orderForDeal.asset.name &&
          currentOrder.asset.quantity_type === orderForDeal.asset.quantity_type &&
          new Decimal(currentOrder.asset.price).eq(orderForDeal.asset.price)
        )
      ) {
        dealAction(orderForDeal, currentOrder)
      }
    }
    if (currentOrder.response_offer_op_id) currentOrder.asset.quantity = 0
  })
  return _orders.filter(i => !new Decimal(i.asset.quantity).eq(0))
}

const checkBanknotes = (currentOrder, orderForDeal) => {
  return currentOrder.banknotes?.some(i => orderForDeal.banknotes.some(j => i === j))
}

// const checkPlace = (currentOrder, orderForDeal) => {
//   return currentOrder.country === orderForDeal.country && currentOrder.city === orderForDeal.city
// }

const checkTypes = (currentOrder, orderForDeal) => {
  const isSelling = currentOrder.type === 'sell'
  const paymentTypes = currentOrder.payment_types?.filter(i => {
    return orderForDeal.payment_types?.some(j => i === j)
  }) || []
  // const isAsset = [currentOrder, orderForDeal].some(i => i.isAssetOffer)
  const result = paymentTypes.map(paymentType => {
    let condition = currentOrder.country === orderForDeal.country
    if (paymentType === PAYMENT_TYPE_NAMES.CASH) condition = currentOrder.country === orderForDeal.country && currentOrder.city === orderForDeal.city && checkBanknotes(currentOrder, orderForDeal)
    const attrs = [currentOrder, orderForDeal]
    // if (isAsset) attrs.push('asset.quantity')
    return condition && checkMinMatch(...attrs)
  })
  return result.some(Boolean)
}

const checkExchangeRate = (currentOrder, orderForCheck) => {
  return currentOrder.exchange_rate === orderForCheck.exchange_rate
}

export const isMKTUNumbersMatch = (firstOffer, secondOffer) => {
  const firstArr = firstOffer.mktu_numbers
  const secondArr = secondOffer.mktu_numbers
  const availableNumbersMap = {}
  secondArr.forEach(number => {
    if (number.length < 3) return availableNumbersMap[number] = true
    const className = number.slice(0, number.length === 5 ? 1 : 2)
    availableNumbersMap[className] = true
    availableNumbersMap[number] = true
  })
  return firstArr.some(number => availableNumbersMap[number])
}

const checkMinMatch = (currentOrder, orderForDeal, checkingProp = 'second_amount') => {
  let checkingValue = orderForDeal[checkingProp]
  const splitted = checkingProp.split('.')
  if (splitted[0] === 'asset') checkingValue = orderForDeal.asset[splitted[1]]
  return new Decimal(checkingValue || 0).gte(orderForDeal.min_match_amount || 0)
}

export const needP2PMatch = (currentOrder, orderForDeal) => {
  const checkFunctions = [checkTypes]
  return checkFunctions.map(f => f(currentOrder, orderForDeal)).some(Boolean)
}

export const convertFormModelToOffer = data => {
  const {
    exchange_rate,
    owned_amount,
    owned_currency,
    type,
    user_account_info,
    wanted_amount,
    wanted_currency,
    banknotes,
    city,
    country,
    min_match_amount,
    requisites_hash,
    response_offer_op_id,
    payment_types,
    operation_type,
    additional_info,
    mktu_numbers,
  } = data
  const result = {
    exchange_rate,
    type,
    user_account_info,
    banknotes,
    city,
    country,
    min_match_amount,
    requisites_hash,
    response_offer_op_id,
    payment_types,
    operation_type,
    additional_info,
    mktu_numbers,
  }
  if (type === 'buy') {
    result.first_amount = owned_amount
    result.first_currency = owned_currency
    result.second_amount = wanted_amount
    result.second_currency = wanted_currency
  } else {
    result.first_amount = wanted_amount
    result.first_currency = wanted_currency
    result.second_amount = owned_amount
    result.second_currency = owned_currency
  }
  return result
}

export const downloadDocxFromTemplate = async (data, template, resultFileName = 'result.docx') => {
  const response = await fetch(template);
  const arrayBuffer = await response.arrayBuffer();

  const zip = new PizZip(arrayBuffer);
  const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });

  // Заменяем метки в шаблоне на данные из data
  doc.render(data);

  const blob = doc.getZip().generate({ type: "blob" });
  saveAs(blob, resultFileName);
}

export const getAdditionalDealInfoFromWs = (propName, item) => {
  switch (propName) {
    case 'statuses':
      return `статус изменен на ${item.statusLabel}`
    case 'message':
      return 'новое сообщение в чате'
    case 'agreement_docs':
      const lastDoc = item[propName]?.at(-1)
      if (!lastDoc.signed) return 'новый подписанный с одной стороны агентский договор'
      return 'новый подписанный с двух сторон агентский договор'
    case 'act_docs':
      const lastAct = item[propName]?.at(-1)
      if (!lastAct.signed) return 'новый подписанный с одной стороны акт выполненных работ'
      return 'новый подписанный с двух сторон акт выполненных работ'
    case 'accepted_agreement':
      return 'агентский договор подтверждён'
    case 'accepted_act':
      return 'акт выполненных работ подтверждён'
    case 'arbitration':
      if (item.isArbitration) return 'арбитраж'
      return 'арбитраж снят'
  }
}
