import { AutoexchangeSignatures } from '@/modules/Signatures'
import { getDeepSortedPropsObj } from '@/utils/common'
import { SignService } from '@/Services/SignService'
import { actionSignature } from '@/Methods/ActionSignature'
import sha256 from 'js-sha256'

export default class P2PDealDocument {
  constructor (fileData, data, offer_operation_id, doc_type, sell_offer_operation_id, signed = false) {
    const {
      channel,
      chaincode,
      action: { addDealDocument: action }
    } = AutoexchangeSignatures
    this.channel = channel
    this.chaincode = chaincode
    this.action = action
    this.docType = doc_type
    this.fileData = fileData
    this.offer_operation_id = offer_operation_id
    this.sell_offer_operation_id = sell_offer_operation_id
    this.signed = signed
    this.hash = sha256(data)
  }

  getSignedRequest (key, userId) {
    const data = getDeepSortedPropsObj({
      doc_hash: this.hash,
      doc_type: this.docType,
      offer_operation_id: this.offer_operation_id,
      sell_offer_operation_id: this.sell_offer_operation_id,
      signed: this.signed
    })
    let signService = new SignService(key, userId)
    let actionId = actionSignature(
      this.channel,
      this.chaincode,
      this.action
    )
    const signed_request = signService.createSignedRequest(actionId, data)
    const request = {
      signed_request
    }
    const formData = new FormData()
    formData.append('file', this.fileData)
    formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }))
    return formData
  }
}
