import { useStore } from 'vuex'
import { computed } from 'vue'
import { ProcessTypes } from '@/models/ProcessTypes'
import { currencies } from '@/models/Currencies'
import Decimal from 'decimal.js'

export default function (process, amount, currency) {
  const store = useStore()
  const commissionTotalAmount = computed(() => {
    const truncatePrecision = store.getters.getProcessCurrencyObj(ProcessTypes.p2p, 'LION')?.truncate_precision || 2
    return new Decimal(store.getters.getCommissionAmount(ProcessTypes.p2p, amount.value, currency) || 0).plus(store.getters.getCommissionCEAmount(ProcessTypes.p2p, amount.value, currency)).toFixed(+truncatePrecision)
  })
  return {
    commissionTotalAmount
  }
}
