<script setup>
import { computed } from 'vue'

const props = defineProps({
  willGet: { type: String, required: true },
  willGive: { type: String, required: true },
  notEnoughMoney: { type: Boolean, default: false },
  notEnoughLion: { type: Boolean, default: false },
  commissionTotalAmount: { type: String, required: true },
  exchangeRate: { type: String, default: undefined },
  exchangeRatePair: { type: String, default: undefined },
  amountForReserve: { type: String, default: undefined },
  currencyForReserve: { type: String, default: undefined },
})

const willBlockGridValue = computed(() => {
  const result = ['1fr', '1fr']
  if (props.exchangeRate) result.push('1fr')
  return result.join(' ')
})
</script>

<template lang="pug">
.ce-p2p-add-form-info-block
  .ce-p2p-add-form-info-block_will-block
    .ce-p2p-add-form-info-block_info
      .ce-p2p-add-form-info-block_info-title {{ 'Вы получите' }}
      .ce-p2p-add-form-info-block_info-content
        .ce-p2p-add-form-info-block_info-amount
          .ce-p2p-add-form-info-block_info-amount-value {{ willGet }}
    .ce-p2p-add-form-info-block_info
      .ce-p2p-add-form-info-block_info-title  {{ 'Вы отдаете' }}
      .ce-p2p-add-form-info-block_info-content(
        :class="{ error: notEnoughMoney }"
      )
        .ce-p2p-add-form-info-block_info-amount(
          :class="{ error: notEnoughMoney }"
        )
          .ce-p2p-add-form-info-block_info-amount-value {{ willGive }}
    .ce-p2p-add-form-info-block_info(v-if="exchangeRate" )
      .ce-p2p-add-form-info-block_info-title {{ 'Курс' }}
      .ce-p2p-add-form-info-block_info-content
        .ce-p2p-add-form-info-block_info-amount
          .ce-p2p-add-form-info-block_info-amount-value {{ exchangeRate }} {{ exchangeRatePair }}
  .ce-p2p-add-form-info-block_security-block
    .ce-p2p-add-form-info-block_info
      .ce-p2p-add-form-info-block_info-title {{ 'Заблокировано для обеспечения сделки' }}
      .ce-p2p-add-form-info-block_info-content
        .ce-p2p-add-form-info-block_info-amount
          .ce-p2p-add-form-info-block_info-amount-value {{ amountForReserve || 0 }} {{ currencyForReserve }}
  .ce-p2p-add-form-info-block_info-commission(
    :class="{ error: notEnoughLion }"
  )
    .ce-p2p-add-form-info-block_info-commission-title Комиссия
    .ce-p2p-add-form-info-block_info-commission-value {{ commissionTotalAmount }} LION
</template>

<style scoped lang="scss">
.ce-p2p-add-form-info-block {
  display: grid;
  grid-gap: 0.75rem;
  font-size: 0.75rem;
  @include forDesktop1280_v2 {
  }
  @include forDesktopFullHd_v2 {
  }
  &_will-block {
    display: grid;
    grid-template-columns: v-bind(willBlockGridValue);
    grid-gap: 0.75rem;
    word-break: break-word;
  }
  &_info {
    @include forDesktop1280_v2 {
      //align-self: flex-end;
      //grid-area: 5 / 2 / 6 / 3;
    }
    @include forDesktopFullHd_v2 {
      //align-self: unset;
    }

    &-content {
      //border: 1px solid $Grey-90;
      padding: 12px;
      border-radius: 4px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &.error {
        border: 1px solid $StatusRed;
      }
    }

    &-title {
      @extend .ce-text-base, .ce-text-sm, .ce-text--medium;
      color: $Grey-50;
    }

    &-amount-value, &-commission-value {
      @extend .ce-text--bold;
      font-size: 1rem;
    }

    &-amount, &-commission {
      display: flex;
      gap: 10px;
      min-height: 1.25rem;

      &.error {
        & > * {
          color: $StatusRed !important;
        }
      }
    }
  }
}
</style>
