import sha256 from 'js-sha256'

export default class P2POfferFile {
  constructor (fileData, base64String) {
    this.fileData = fileData
    this.hash = sha256(base64String)
  }

  getFileFormData () {
    const formData = new FormData()
    formData.append('file', this.fileData)
    return { formData, hash: this.hash }
  }
  static async getBase64FromFile (fileObj) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1])
      }
      reader.readAsDataURL(fileObj)
    })
  }
}
