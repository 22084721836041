import { NETWORK_VERSION } from '@/constants/input'

export default class {
  constructor (web3Service) {
    this.web3Service = web3Service
  }

  get network() {
    return this.web3Service.ethNetwork
  }

  async transfer(toAddress, amount) {
    const { currentProvider } = this.network
    if (currentProvider?.networkVersion !== NETWORK_VERSION) throw new Error('Invalid network ID')
    else if (!currentProvider.selectedAddress) throw new Error('No address selected')
    else return this.network.sendTransaction({
      from: currentProvider.selectedAddress,
      to: toAddress,
      value: this.web3Service.utils.toWei(amount)
    })
  }
}
