import BaseSignModel from '@/models/BaseSignModel'
import { AutoexchangeSignatures } from '@/modules/Signatures'
import { getDeepSortedPropsObj } from '@/utils/common'
import { SignService } from '@/Services/SignService'
import { actionSignature } from '@/Methods/ActionSignature'
import sha256 from 'js-sha256'

export default class P2PDealAddress {
  constructor (data, offer_operation_id, sell_offer_operation_id) {
    const {
      channel,
      chaincode,
      action: { addAeDealAddress: action }
    } = AutoexchangeSignatures
    this.channel = channel
    this.chaincode = chaincode
    this.action = action
    this.addressData = data
    this.offer_operation_id = offer_operation_id
    this.sell_offer_operation_id = sell_offer_operation_id
  }

  getSignedRequest (key, userId) {
    const addressData = getDeepSortedPropsObj(this.addressData)
    const addressHash = sha256(JSON.stringify(addressData))
    const data = getDeepSortedPropsObj({
      address: addressHash,
      offer_operation_id: this.offer_operation_id,
      sell_offer_operation_id: this.sell_offer_operation_id
    })
    let signService = new SignService(key, userId)
    let actionId = actionSignature(
      this.channel,
      this.chaincode,
      this.action
    )
    const signed_request = signService.createSignedRequest(actionId, data)
    return {
      address: addressData,
      signed_request,
    }
  }
}
