import moment from 'moment-mini'

export default function (messageType, data) {
  const timestamp = Math.floor(new Date(data.timestamp).getTime())
  return {
    timestamp,
    userId: data.user_id,
    type: messageType,
    payload: data,
    _time: moment.unix(timestamp).format('DD.MM.YY HH:mm:ss')
  }
}
