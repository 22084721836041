import http from '@/utils/http'

export const getUnitsOfMeasurements = () => {
  return http.get('/admin/units-of-measurements')
}

export const getMKTUProducts = () => {
  return http.get('/admin/mktu')
}

export const getP2PArbitrationDeals = () => {
  return http.get('/autoexchange/arbitration/deals')
}
