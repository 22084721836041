export default {
  install (Vue) {
    // console.log('Error plugins start')
    let Ru = {
      autoexchangeRequestCreated: 'Заявка на покупку цифровых активов создана',
      p2pRequestCreated: 'Заявка P2P создана',
      autoexchangeTransactionNotCreated: 'Ошибка при создании заявки на покупку цифровых активов',
      incorrectData: 'Введены не корректные данные',
      dataSent: 'Данные отправлены',
      valueGreater: 'Значение больше заказанного',
      failedCreateRequest: 'Не удалось создать заявку на обмен',
      pairAlreadyExists: 'Заявка с такой парой уже существует',
      problemsConnecting: 'Возникли проблеммы соединения с сервером, попробуйте обновить страницу.',
      passwordDoesNotMatchRequirements: 'Пароль не подходит под требования',
      amlNotVerified: 'AML не подтвержден',
      termOfUseNotVerified: 'Term of Use не подтвержден',
      confirmRobot: 'Подтвердите, что вы не робот',
      enteredDataNotEmail: 'Введенные данные не являются email',
      enteredDataNotUserId: 'Введенные данные не являются UserId',
      loginNotFiled: 'Поле логин не заполнено',
      loginNotMail: 'Поле Login не является почтой',
      operationFailed: 'Опреция не удалась. Попробуйте повторить позже',
      passwordNotEntered: 'Не введен пароль',
      dataNotCorrectly: 'Не все данные введены верно',
      loginFailed: 'Логин, пароль или приватный ключ не верен, обновите страницу и попробуйте еще раз',
      notFieldsFilled: 'Не все обязательные поля заполнены',
      currenciesRestrictions: 'Не попали в ограничения валют',
      withdrawalSuccessfully: 'Вывод успешно совершен',
      withdrawalNotSuccessfully: 'Вывод не удался',
      chooseCurrency: 'Выберите валюту',
      notEnoughFunds: 'Не достаточно средств',
      operationRestrictionsFailed: 'Не удалось получить ограничения по для операции',
      commissionFailed: 'Не удалось забыть комиссию',
      amountCannotEmpty: 'Поле amount не может быть пустым',
      notValidEthWallet: 'Похоже вы ввели не eth кошелек',
      failedGetRequests: 'Не удалось получить завки для доски объявлений',
      selectOption: 'Выберите операцию',
      error: 'Во время выполнения операции произошла ошибка',
      success: 'Операция выполнена успешно',
      currencyNotFound: 'Валюты не найдены',
      commissionNotReceived: 'Комиссия не получена',
      currencyNotUsed: 'Данная валюта не может быть использована для текущей операции',
      actionConfirmed: 'Действие подтверждено',
      notAllData: 'Введены не все данные для завершения операции',
      insufficientFunds: 'Недостаточно средств для завершения операции',
      insufficientFundsForCommission: 'Недостаточно средств для оплаты комиссии',
      walletsNotReceived: 'Список кошельков не получен',
      paymentsListNotReceived: 'Список платежей не получен',
      failedToLogOut: 'Не удалось пройти процедуру выхода из системы',
      selectPemFile: 'Выберите файл с разрешением .pem',
      noCertificateSelected: 'Сертификат не выбран',
      certificateSelected: 'Сертификат выбран',
      yourActionConfirmed: 'Ваше действие подтверждено',
      listOfExternalAccountsNotReceived: 'Список внеших счетов не получен',
      emailNotSent: 'E-mail не отправлен',
      emailSent: 'E-mail отправлен',
      enterYourKey: 'Введите ваш ключ',
      keyNotEntered: 'Ключ не введен',
      keyEntered: 'Ключ введен',
      checkEnteredData: 'Проверьте введенные данные, и повторите запрос',
      chooseCertificate: 'Выберите сертификат',
      registrationCompleted: 'Регистрация прошла успешно',
      somethingWentWrong: 'Что-то пошло не так',
      passwordMismatch: 'Пароли не совпадают',
      notAllRegistrationConditions: 'Не все условия регистрации выполнены',
      incomingTransactionsNotReceived: 'Список входящих транзакций не получен',
      walletActivated: 'Кошелек активирован',
      walletNotActivated: 'Кошелек не активирован',
      walletLocked: 'Кошелек заблокирован',
      walletNotLocked: 'Кошелек не заблокирован',
      accountBlocked: 'Счет заблокирован',
      accountNotBlocked: 'Счет не заблокирован',
      accountActivated: 'Счет активирован',
      accountNotActivated: 'Счет не активирован',
      accountCreated: 'Счет создан',
      accountNotCreated: 'Счет не создан',
      requestCreated: 'Запрос создан',
      requestNotCreated: 'Запрос не создан',
      walletCreated: 'Кошелек создан',
      walletNotCreated: 'Кошелек не создан',
      exchangeApplicationCanceled: 'Заявка на обмен отменена',
      exchangeRequestNotCanceled: 'Не удалось отменить заявку на обмен',
      exchangeRequestRejected: 'Заявка на обмен отклонена',
      exchangeRequestNotRejected: 'Не удалось отклонить заявку на обмен',
      exchangeRequestConfirmed: 'Заявка на обмен подтверждена',
      exchangeRequestNotConfirmed: 'Не удалось подтвердить заявку на обмен',
      walletAdded: 'Кошелек добавлен',
      walletNotAdded: 'Кошелек не добавлен',
      exchangeRequestCreated: 'Заявка на обмен создана',
      exchangeRequestNotCreated: 'Заявка на обмен не создана',
      transferTransactionCreated: 'Транзакция трансфера создана',
      transferTransactionNotCreated: 'Транзакция трансфера не создана',
      confirmExchange: 'Подтвердите обмен',
      confirmApplicationRejection: 'Подтвердите отклонение заявки',
      confirmCancellation: 'Подтвердите отмену заявки',
      outgoingTransactionListNotReceived: 'Список исходящих транзакция не получен',
      outgoingTransactionHistoryNotReceived: 'История исходящих транзакций не получена',
      copiedSuccessfully: 'Скопировано успешно',
      copyFailed: 'Копирование не удалось',
      incomingTransactionListNotReceived: 'Список входящих транзакций не получен',
      incomingTransactionHistoryNotReceived: 'История входящих транзакций не получена',
      confirmTheTransferOfFundsInTheAmountOf: 'Подтвердите перевод средств в размере',
      confirmFundsExchange: 'Подтвердите обмен средств',
      confirmWithdrawal: 'Подтвердите вывод средств в размере',
      confirmCreateWallet: 'Подтвердите создание внешнего кошелька для валюты',
      walletUnlock: 'Внимание! Подтвердите разблокировку кошелька',
      walletLock: 'Внимание! Подтвердите блокировку кошелька',
      accountUnlock: 'Внимание! Подтвердите разблокировку счета',
      accountLock: 'Внимание! Подтвердите блокировку счета',
      createNewWallet: 'Подтвердите создание нового кошелька',
      createNewAccount: 'Подтвердите создание нового счета',
      requiredFieldsAreEmpty: 'Не все обязательные поля заполнены',
      dataSaved: 'Данные сохранены',
      dataNotSaved: 'Данные не сохранены',
      useCameraNotPermitted: 'Вы запретили использование камеры на данном сайте. Пройдите в настройки браузера и разрешите использование камеры',
      successfullySubscribedToNewsletter: 'Вы подписаны на новостную рассылку!',
      cantLoadExternalTx: 'Невозможно загрузить информацию о транзакции',

      invalidWalletAddress: 'Неверно указан адрес кошелька',
    }
    let En = {
      autoexchangeRequestCreated: 'An application for the purchase of digital assets has been created',
      p2pRequestCreated: 'P2P request created',
      autoexchangeTransactionNotCreated: 'Error when creating an application for the purchase of digital assets',
      incorrectData: 'Incorrect data entered',
      dataSent: 'Data sent',
      valueGreater: 'Value is greater than ordered',
      failedCreateRequest: 'Failed to create an exchange request',
      pairAlreadyExists: 'An application with such a pair already exists',
      problemsConnecting: 'There are problems connecting to the server, please try refreshing the page.',
      passwordDoesNotMatchRequirements: 'Password does not match requirements',
      enteredDataNotEmail: 'The entered data is not email',
      enteredDataNotUserId: 'The entered data is not a UserId',
      amlNotVerified: 'aml not verified',
      termOfUseNotVerified: 'Term of Use not verified',
      confirmRobot: 'Confirm that you are not a robot',
      loginNotFiled: 'Login field is not filled',
      loginNotMail: 'Login field is not mail',
      operationFailed: 'The operation failed. Try again later',
      passwordNotEntered: 'Password not entered',
      dataNotCorrectly: 'Not all data is entered correctly',
      loginFailed: 'Login, password or correct key is not correct, refresh the page and try again',
      notFieldsFilled: 'Not all required fields are filled',
      currenciesRestrictions: 'Didn\'t hit the currencies restrictions',
      withdrawalSuccessfully: 'Withdrawal successfully completed',
      withdrawalNotSuccessfully: 'Withdrawal not successfully',
      notEnoughFunds: 'Not enough funds',
      chooseCurrency: 'Choose currency',
      operationRestrictionsFailed: 'Failed to get software restrictions for operation',
      commissionFailed: 'Failed to get commission',
      amountCannotEmpty: 'The amount field cannot be empty\n',
      notValidEthWallet: 'Seems like you have entered not valid eth wallet',
      failedGetRequests: 'Failed to get requests for the message board\n',
      selectOption: 'Select option',
      error: 'An error occurred during the operation',
      success: 'Operation completed successfully',
      currencyNotFound: 'No currencies found',
      commissionNotReceived: 'Commission not received',
      currencyNotUsed: 'This currency cannot be used for the current transaction.',
      actionConfirmed: 'Action confirmed',
      notAllData: 'Not all data entered to complete the operation',
      insufficientFunds: 'Not enough funds to complete the operation',
      insufficientFundsForCommission: 'Not enough funds to pay the commission',
      walletsNotReceived: 'Wallet list not received',
      paymentsListNotReceived: 'Payment list not received',
      failedToLogOut: 'Failed to log out',
      selectPemFile: 'Select .pem file',
      noCertificateSelected: 'No certificate selected',
      certificateSelected: 'Сertificate selected',
      yourActionConfirmed: 'Your action has been confirmed',
      listOfExternalAccountsNotReceived: 'List of external accounts not received',
      emailNotSent: 'Email not sent',
      emailSent: 'Email sent',
      enterYourKey: 'Enter your key',
      keyNotEntered: 'Key not entered',
      keyEntered: 'Key entered',
      checkEnteredData: 'Check your entered data and try again',
      chooseCertificate: 'Choose a certificate',
      registrationCompleted: 'Registration completed successfully',
      somethingWentWrong: 'Something went wrong',
      passwordMismatch: 'Password mismatch',
      notAllRegistrationConditions: 'Not all registration conditions are met',
      incomingTransactionsNotReceived: 'Incoming transaction list not received',
      walletActivated: 'Wallet activated',
      walletNotActivated: 'Wallet not activated',
      walletLocked: 'Wallet is locked',
      walletNotLocked: 'Wallet not locked',
      accountBlocked: 'Account blocked',
      accountNotBlocked: 'Account not blocked',
      accountActivated: 'Account activated',
      accountNotActivated: 'Account not activated',
      accountCreated: 'Account created',
      accountNotCreated: 'Account not created',
      requestCreated: 'Request created',
      requestNotCreated: 'Request not created',
      walletCreated: 'Wallet Created',
      walletNotCreated: 'Wallet not created',
      exchangeApplicationCanceled: 'Exchange request canceled',
      exchangeRequestNotCanceled: 'Failed to cancel exchange bunny',
      exchangeRequestRejected: 'Exchange request rejected',
      exchangeRequestNotRejected: 'Failed to decline exchange request',
      exchangeRequestConfirmed: 'Exchange request confirmed',
      exchangeRequestNotConfirmed: 'Failed to confirm exchange request',
      walletAdded: 'Wallet added',
      walletNotAdded: 'Wallet not added',
      exchangeRequestCreated: 'Exchange request created',
      exchangeRequestNotCreated: 'No exchange request created',
      transferTransactionCreated: 'Transfer transaction created',
      transferTransactionNotCreated: 'Transfer transaction not created',
      confirmExchange: 'Confirm Exchange',
      confirmApplicationRejection: 'Confirm application rejection',
      confirmCancellation: 'Confirm cancellation',
      outgoingTransactionListNotReceived: 'Outgoing Transaction List Not Received',
      outgoingTransactionHistoryNotReceived: 'Outgoing transaction history not received',
      copiedSuccessfully: 'Copied successfully',
      copyFailed: 'Copy failed',
      incomingTransactionListNotReceived: 'Incoming transaction list not received',
      incomingTransactionHistoryNotReceived: 'Incoming transaction history not received',
      confirmTheTransferOfFundsInTheAmountOf: 'Confirm the transfer of funds in the amount of',
      confirmFundsExchange: 'Confirm exchange of funds',
      confirmWithdrawal: 'Confirm the withdrawal of funds in the amount of',
      confirmCreateWallet: 'Confirm the creation of an external currency wallet',
      walletUnlock: 'Attention! Confirm Wallet Unlock',
      walletLock: 'Attention! Confirm Wallet Lock',
      accountUnlock: 'Attention! Confirm account unlock',
      accountLock: 'Attention! Confirm Account Lock',
      createNewWallet: 'Confirm New Wallet Creation',
      createNewAccount: 'Confirm New Account Creation',
      requiredFieldsAreEmpty: 'Not all required fields are filled',
      dataSaved: 'Data saved',
      dataNotSaved: 'Data not saved',
      useCameraNotPermitted: 'You have banned the use of the camera on this site. Go to your browser settings and enable the camera',
      successfullySubscribedToNewsletter: 'You have been subscribed to newsletter!',
      cantLoadExternalTx: 'Can\'t load transaction info',

      invalidWalletAddress: 'Invalid wallet address',
    }
    Vue.config.globalProperties.$selectMessageText = function (lang, text) {
      const locale = window.$i18n.locale
      let message = null
      switch (locale) {
        case 'en':
          message = En[text]
          break
        case 'ru':
          message = Ru[text]
          break
      }
      return message ?? text
    }
  }
}
