import { AutoexchangeSignatures } from '@/modules/Signatures'
import P2PDealBaseSignModel from '@/models/P2P/P2PDealBaseSignModel'

export default class P2PDealAddressAccept extends P2PDealBaseSignModel{
  constructor (address, offer_operation_id, sell_offer_operation_id) {
    const {
      channel,
      chaincode,
      action: { acceptDealAddress: action }
    } = AutoexchangeSignatures
    const modifiedData = {
      address,
      offer_operation_id,
      sell_offer_operation_id
    }
    const payload = {
      data: modifiedData,
      channel,
      chaincode,
      action
    }
    super(payload)
  }
}
