import { useStore } from 'vuex'
import P2PDealArbitration from '@/models/P2P/P2PDealArbitration'
import { notify } from '@kyvg/vue3-notification'
import { toggleDealArbitration } from '@/api/p2p'

export default function (dealObj) {

  const store = useStore()
  const toggleDealArbitrationHandler = async (userData) => {
    store.commit('SetLoaderFlag', true)
    try {
      const {
        offer_operation_id,
        sell_offer_operation_id
      } = dealObj
      const signedPayload = new P2PDealArbitration({
        offer_operation_id,
        sell_offer_operation_id
      }).getSignedRequest(userData.key, store.state.UserName)
      await toggleDealArbitration(signedPayload)
      notify({
        title: 'Успех',
        text: '',
        type: 'success'
      })
    } catch (err) {
      console.error(err)
      notify({
        type: 'error',
        title: window.$t('error_title'),
        text: window.$t('something_went_wrong')
      })
    } finally {
      store.commit('setShowGlobalConfirm', { value: false })
      store.commit('SetLoaderFlag', false)
    }
  }

  return { toggleDealArbitrationHandler }
}
