import BaseSignModel from '@/models/BaseSignModel'
import { AutoexchangeSignatures } from '@/modules/Signatures'
import P2PDealBaseSignModel from '@/models/P2P/P2PDealBaseSignModel'

export default class P2PDealDocumentAccept extends P2PDealBaseSignModel{
  constructor (data) {
    const {
      channel,
      chaincode,
      action: { acceptDealDocument: action }
    } = AutoexchangeSignatures
    const payload = {
      data,
      channel,
      chaincode,
      action
    }
    super(payload)
  }
}
