import axios from 'axios'
import { getMKTUProducts, getP2PArbitrationDeals, getUnitsOfMeasurements } from '@/api/admin'
import DealModel from '@/models/P2P/DealModel'

const state = () => ({
  matchingSuspended: false,
  unitsOfMeasurements: [],
  mktuClassesMap: {},
  mktuProductsMap: {},
  p2pArbitrationDeals: []
})

const mutations = {
  setMatchingSuspended (state, val) {
    state.matchingSuspended = val
  },
  setUnitsOfMeasurements (state, data) {
    state.unitsOfMeasurements = data
  },
  setMKTU (state, mktuClassesMap) {
    state.mktuClassesMap = mktuClassesMap
  },
  setMKTUProducts (state, mktuProductsMap) {
    state.mktuProductsMap = mktuProductsMap
  },
  setP2PArbitrationDeals (state, deals) {
    state.p2pArbitrationDeals = deals
  },
  deleteP2PArbitrationDeal (state, operationId) {
    const idx = state.p2pArbitrationDeals.findIndex(i => i.operation_id === operationId)
    if (~idx) state.p2pArbitrationDeals.splice(idx, 1)
  }
}

const actions = {
  saveAdminItem ({ state, rootState }, { apiMethod, data }) {
    const reqObj = {
      method: 'POST',
      url: `${rootState.backEndAddress}${apiMethod}`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    }
    if (data) reqObj.data = JSON.stringify(data)
    return axios(reqObj)
  },
  async fetchMatchingStatus ({ commit, rootState }) {
    try {
      const response = await axios.get(`${rootState.backEndAddress}/admin/is-matching-suspended`)
      if (response?.data?.status === 200) {
        commit('setMatchingSuspended', response.data.payload)
      }
    } catch (err) {
      console.log({ err })
    }
  },
  async fetchUser ({ rootState }, userId) {
    try {
      const response = await axios.get(`${rootState.backEndAddress}/user/${userId}`)
      if (response?.data?.status === 200) {
        return response.data.payload
      }
    } catch (err) {
      console.log({ err })
      return null
    }
  },
  async fetchUnitsOfMeasurements ({ commit }) {
    try {
      const data = await getUnitsOfMeasurements()
      commit('setUnitsOfMeasurements', data)
    } catch (err) {
      console.log('fetchUnitsOfMeasurements', err)
    }
  },
  async fetchMKTUProducts ({ commit }) {
    try {
      const products = await getMKTUProducts()
      const mktuProductsMap = {}
      const mktuClassesMap = {}
      products.forEach((product) => {
        if (!mktuClassesMap[product.klass]) mktuClassesMap[product.klass] = []
        mktuClassesMap[product.klass].push(product)
        mktuProductsMap[product.number] = product
      }, {})
      commit('setMKTU', mktuClassesMap)
      commit('setMKTUProducts', mktuProductsMap)
    } catch (err) {
      console.log('fetchMKTUProducts', err)
    }
  },
  async fetchP2PArbitrationDeals ({ commit }) {
    try {
      const data = await getP2PArbitrationDeals()
      const deals = (data || []).map(i => {
        return new DealModel({
          ...i.ae_deal_ext,
          address_hashes: i.ae_deal_ext.addresses,
          ...i.ae_deal,
          addresses: i.addresses,
          buy_product_details: i.buy_product_details,
          buy_requisites: i.buy_requisites,
          sell_product_details: i.sell_product_details,
          sell_requisites: i.sell_requisites,
          sell_offer: i.sell_offer,
          buy_offer: i.buy_offer
        }, i)
      })
      commit('setP2PArbitrationDeals', deals)
    } catch (err) {
      console.error(err)
    }
  }
}

const getters = {
  isMatchingSuspended: state => state.matchingSuspended,
  getMKTUCodesByClass: state => classValue => {
    return state.mktuClassesMap[classValue]
  },
  getMKTUClasses: state => Object.keys(state.mktuClassesMap),
  getMKTUProducts: state => Object.values(state.mktuClassesMap).flat()
}

export default {
  state,
  mutations,
  actions,
  getters
}
