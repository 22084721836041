import { AutoexchangeSignatures } from '@/modules/Signatures'
import P2PDealBaseSignModel from '@/models/P2P/P2PDealBaseSignModel'

export default class P2PDealArbitration extends P2PDealBaseSignModel{
  constructor (data) {
    const {
      channel,
      chaincode,
      action: { arbitration: action }
    } = AutoexchangeSignatures
    super({ channel, chaincode, action, data })
  }
}
