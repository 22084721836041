<template>
  <div
    v-if="flagShow"
    class="Confirm ce-confirm"
  >
    <div class="Global_Card">
      <div class="Global_Title Global_Title_h3">
        {{ $t('confirm_title') }}
      </div>
      <div class="ce-confirm-content Confirm-Content">
        <ce-text-field
          :model-value="login"
          :label="$t('confirm_login')"
          disabled
          type="text"
        />
        <ce-text-field
          id="password"
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          name="password"
          :placeholder="$t('confirm_password_placeholder')"
          :label="$t('confirm_password')"
          autocomplete="on"
          @change="validateInputPassword"
          @focus="changeReady"
        >
          <template #append-input>
            <span @click="showPassword = !showPassword"><i class="Icon_Eye" /></span>
          </template>
        </ce-text-field>

        <div class="Login-PEMKey">
          <label
            id="LabelForFile"
            for="file"
            class="ce-confirm-pem-label Login-LabelButton"
          >
            <svg
              class="Login-LabelButton_img"
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 1H3C1.89543 1 1 1.89543 1 3V19C1 20.1046 1.89543 21 3 21H15C16.1046 21 17 20.1046 17 19V8L10 1Z"
                stroke="#BFBFBF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 1V8H17"
                stroke="#BFBFBF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <br>
            {{ $t('confirm_insert_pem') }}
          </label>
          <input
            id="file"
            name="file"
            accept=".pem"
            type="file"
            class="Login-Input_File"
            @change="insertPem"
          >

          <label
            id="LabelForText"
            for="text"
            class="ce-confirm-pem-label Login-LabelButton"
            @click="showTextPem = !showTextPem"
          ><img
            class="Login-LabelButton_img"
            src="../assets/images/EnterFile.svg"
            alt="close"
          ><br>{{ $t('confirm_enter_pem') }}</label>
          <ce-textarea
            v-if="showTextPem"
            id="text"
            v-model="text"
            name="text"
            class="ce-confirm-text-pem"
            @change="validatePemKeyText"
          />
        </div>
        <div class="Confirm-Button">
          <button
            class="ce-button ce-button--yellow"
            @click="confirmAction"
          >
            {{ $t('confirm_action_confirm') }}
          </button>
          <button
            class="ce-button ce-button--bordered-grey"
            @click="hideConfirm"
          >
            {{ $t('confirm_action_cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { NativeBiometric } from 'capacitor-native-biometric'
import { mapActions, mapMutations, mapState } from 'vuex'
import CeTextField from '@/components/common/CeTextField.vue'
import CeTextarea from '@/components/common/CeTextarea.vue'

let errPass = false

export default {
  components: { CeTextarea, CeTextField },
  props: {
    flagShow: Boolean
  },
  emits: ['confirm-action', 'hide-confirm'],
  computed: {
    ...mapState(['globalConfirmCancelCb'])
  },
  data () {
    return {
      login: localStorage.getItem('user_id'),
      password: '',
      filePem: '',
      text: '',
      showPassword: false,
      showTextPem: false
    }
  },
  methods: {
    ...mapMutations(['setShowGlobalConfirm']),
    ...mapActions(['checkShowBiometric']),
    hideConfirm () {
      this.filePem = ''
      this.text = ''
      this.password = ''
      if (!this.globalConfirmCancelCb) this.setShowGlobalConfirm({ value: false })
      this.$emit('hide-confirm', false)
    },
    insertPem (event) {
      document.getElementById('LabelForFile').classList.remove('InputFileSuccess')
      document.getElementById('LabelForFile').classList.remove('InputFileError')
      let files = event.target
      let reader = new FileReader()
      reader.onload = (f) => {
        this.filePem = reader.result
        this.text = reader.result
        document.getElementById('LabelForFile').classList.add('InputFileSuccess')
        // this.validateInput(event)
      }
      reader.readAsText(files.files[0])
    },
    // async checkShowBiometric () {
    //   try {
    //     const result = await NativeBiometric.isAvailable()
    //     if (result.isAvailable) {
    //       const verified = await NativeBiometric.verifyIdentity({
    //         reason: this.$t('biometric_confirm_reason'),
    //         title: this.$t('biometric_confirm_title')
    //       })
    //         .then(() => true)
    //         .catch(() => false);
    //
    //       if(!verified) return;
    //
    //       const credentials = await NativeBiometric.getCredentials({
    //         server: "develop.cryptoenter.com",
    //       });
    //       // в username хранится pem
    //       this.text = credentials.username
    //       this.password = credentials.password
    //       this.confirmAction()
    //     }
    //   } catch (err) {
    //     console.log(err)
    //   }
    // },
    async autoConfirmHandler () {
      const { key, password } = await this.checkShowBiometric({
        reason: this.$t('biometric_confirm_reason'),
        title: this.$t('biometric_confirm_title')
      })
      if (key && password) {
        this.text = key
        this.password = password
        this.confirmAction()
      }
    },
    confirmAction () {
      if (!errPass) {
        if ((this.filePem || this.text)) {
          if (this.password) {
            let userData = {
              login: this.login,
              password: this.password,
              key: this.filePem || this.text
            }
            this.$emit('confirm-action', userData)
            this.filePem = ''
            this.text = ''
            this.password = ''
          } else {
            this.$notify({
              title: this.$t('error_title'),
              text: this.$selectMessageText('En', 'passwordNotEntered'),
              type: 'error'
            })
          }
        } else {
          this.$notify({
            title: this.$t('error_title'),
            text: this.$selectMessageText('En', 'enterYourKey'),
            type: 'error'
          })
        }
      }
    },
    changeReady (event) {
      event.target.classList.remove('ErrorInput')
      event.target.classList.remove('SuccessInput')
    },
    validateInputPassword (event) {
      if (!this.$NewValid({
        typeValidation: 'password',
        dataForValid: {
          password: this.password
        }
      })) {
        event.target.classList.remove('SuccessInput')
        event.target.classList.add('ErrorInput')
        errPass = true
        this.$notify({
          title: this.$t('error_title'),
          text: this.$selectMessageText('En', 'passwordDoesNotMatchRequirements'),
          type: 'error'
        })
      } else {
        event.target.classList.add('SuccessInput')
        event.target.classList.remove('ErrorInput')
        errPass = false
      }
    },
    validatePemKeyText () {
      document.getElementById('LabelForFile').classList.remove('InputFileSuccess')
      document.getElementById('LabelForFile').classList.remove('InputFileError')
      if (this.$NewValid({
        typeValidation: 'PemKey',
        dataForValid: {
          key: this.text
        }
      })) {
        document.getElementById('LabelForText').classList.remove('InputFileError')
        document.getElementById('LabelForText').classList.add('InputFileSuccess')
      } else {
        document.getElementById('LabelForText').classList.remove('InputFileSuccess')
        document.getElementById('LabelForText').classList.add('InputFileError')
      }
    }
  },
  watch: {
    flagShow: {
      immediate: true,
      handler (to) {
        if (to) {
          // FingerprintAuth.isAvailable((result) => {
          //   console.log({ result })
          // }, (error) => {
          //   console.log({ error })
          // })
          // const pass = this.$GetCookie('Password')
          const pass = localStorage.getItem('ps')
          if (pass) this.password = pass
          this.autoConfirmHandler()
        }
      }
    }
  }
}
</script>

<style lang="stylus">
.HideInputPassword
  display none
.Confirm
  z-index 10001
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  background-color: #fff
  &-Login
    margin-bottom 10px
    text-align left
    color: #afafaf;
  &-Content
    padding 40px 0px 0px 0px
    margin 0 auto
    position relative
  &-Button
    display: flex;
    gap: 0.5rem;
    flex-direction column
    justify-content: center
    align-items center
    flex-wrap wrap
    .ce-button
      width: 60%!important;
    &-Pad
      height 40px
      border: 5px solid #fff!important
      width 70%
.Input-Login
  text-align left
  margin-bottom 20px
  font-weight: 500
.Form-Line
  display -webkit-box;
  margin-bottom 23px
</style>

<style scoped lang="scss">
.ce-confirm {
  &-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &-text-pem {
    flex-basis: 100%;
  }
  &-pem-key {
    border-radius: 8px;
  }
}
</style>
