<template>
  <div class="Input ce-input">
    <!--ради отключения автокомплита-->
    <div v-if="!flagConfirm">
      <div class="ce-input-title Global_Title Global_Title_h1 Global_Title_PositionContent">
        {{ $t('input_title') }}
      </div>
      <div class="Input_Form Global_Card_W Global_Text Global_Text_FontSize_M Global_Text_Color_Standart">
        <div class="Input-SelectBlock">
          <ce-dropdown
            v-model="currency"
            :options="listCurrencyForOperation"
            :label="$t('input_choose_currency')"
            autocomplete="off"
            class="ce-input-currency-select"
            :placeholder="$t('input_choose_currency_placeholder')"
            :option-value="null"
            @update:modelValue="changeCurrency"
          />
        </div>
        <div
          v-if="isRub"
          class="rub-input"
        >
          <kyc-variants v-if="!kycVerified" />
          <template v-else>
            <ce-text-field
              v-model="rubAmount"
              label="Сумма в рублях"
            />
            <div
              class="ce-button ce-button--yellow"
              @click="goToGpb"
            >
              Пополнить
            </div>
          </template>
        </div>
        <div
          v-else-if="!inputOperationAvailable"
          class="alert alert-warning"
        >
          {{ $t('input_will_be_available') }}
        </div>
        <div
          v-if="alreadySentToMetaMask"
          class="ce-input-metamask-complete"
        >
          <i
            class="pi pi-check-circle ce-input-metamask-complete__icon"
          />
          <div class="ce-input-metamask-complete__text">
            {{ $t('input_success_metamask_text') }}
          </div>
        </div>
        <div
          v-else-if="inputOperationAvailable"
          style="width: 100%;"
        >
          <div
            v-if="flagBlockShow === 'bitcoin'"
            class="Input-BtcBlock"
          >
            <h2 class="Global_Title Global_Title_h4 Input-ContentHeader">
              {{ $t('input_system_wallet_for_transfer') }}
            </h2>
            <div
              v-if="externalWallet"
              class="Input-BtcAnswer"
            >
              <div class="Input-BtcWalletBlock">
                <p
                  id="BTCWallet"
                  class="Input-Copy-Link"
                >
                  {{ externalWallet.account_id }}
                </p>
                <img
                  class="Input-Copy"
                  src="../assets/images/CopyButton.svg"
                  alt=""
                  @click="copyBtcWallet"
                >
              </div>
              <div class="Input-BtcQrCodeBlock">
                <VueQrcode
                  class="Input_QRcod"
                  :value="externalWallet.account_id"
                />
                <div class="Input-BtcQrCodeText">
                  <p class="Input-InfoText Global_Text Global_Text_FontSize_M">
                    {{ $t('input_btc_min_text') }}
                  </p>
                  <p class="Global-Attention Global_Text Global_Text_FontSize_M">
                    {{ $t('input_commission_attention') }}
                    {{ summaryCommissionPercentage }} %
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="flagBlockShow === 'ethereum'"
            class="Input-EthBlock"
          >
            <div class="Input-EthInput ce-input-eth-input">
              <ce-text-field
                id="ethAmount"
                :label="$t('input_write_amount')"
                :model-value="ethAmount"
                type="text"
                class="ce-input-eth-amount"
                :placeholder="$t('input_amount_placeholder')"
                @update:model-value="onInputEth"
              />
              <button
                v-if="lionEndOperation"
                class="ce-input-eth-btn Input_ButtonNex_Mob Input_Button_Next Global_Button Global_Button_FontSize_S"
                @click="requestEth"
              >
                {{ $t('input_next') }}
              </button>
            </div>
            <div
              v-if="ethAmountResult"
              class="Input-EthResultBlock"
            >
              <p class="Global_Title Global_Title_h4 Input-ContentHeader">
                {{ $t('input_result_amount') }}
              </p>
              <div class="Input-EthResult">
                <p
                  id="ETHAmountResult"
                  class="Input-Copy-Link"
                >
                  {{ ethAmountResult }}
                </p>
                <img
                  class="Input-Copy"
                  src="../assets/images/CopyButton.svg"
                  alt=""
                  @click="copyEthAmount"
                >
              </div>

              <p class="Global_Title Global_Title_h4 Input-ContentHeader">
                {{ $t('input_result_wallet') }}
              </p>
              <div class="Input-EthResult">
                <p
                  id="ETHWalletResult"
                  class="Input-Copy-Link"
                >
                  {{ ethExternalWallet }}
                </p>
                <img
                  class="Input-Copy"
                  src="../assets/images/CopyButton.svg"
                  alt=""
                  @click="copyEthWallet"
                >
              </div>
              <div class="Input_list">
                <ol class="Input-InfoText Global_Text Global_Text_FontSize_M">
                  <li><span class="Input_list_Text">{{ $t('input_eth_text_1', { curr: 'ETH' }) }}</span></li>
                  <br>
                  <li>
                    <span
                      class="Input_list_Text"
                    >{{ $t('input_eth_text_2', { curr: 'ETH' }) }}</span>
                  </li>
                  <br>
                  <li>
                    <span class="Input_list_Text"><b>{{ $t('input_eth_text_3', { curr: 'ETH' }) }}</b> {{ $t('input_eth_text_4', { curr: 'ETH' }) }}</span>
                  </li>
                  <br>
                  <li>
                    <span class="Input_list_Text">{{ $t('input_eth_text_5', { curr: 'ETH' }) }}</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div
            v-if="flagBlockShow === 'tether'"
            class="Input-EthBlock"
          >
            <div class="Input-EthInput ce-input-eth-input">
              <ce-text-field
                id="tetherAmount"
                :model-value="tetherAmount"
                type="text"
                class="ce-input-tether-amount"
                :placeholder="$t('input_amount_placeholder')"
                :label="$t('input_write_amount')"
                @update:model-value="onInputTether"
              />
              <ce-text-field
                id="ethWallet"
                v-model.trim="ethWallet"
                type="text"
                class="ce-input-eth-wallet"
                :placeholder="$t('input_address_placeholder')"
                :label="$t('input_eth_transfer_from')"
              />
              <button
                v-if="lionEndOperation"
                class="ce-input-eth-btn Input_ButtonNex_Mob Input_Button_Next Global_Button Global_Button_FontSize_S Tether_Request"
                @click="requestEth"
              >
                {{ $t('input_next') }}
              </button>
            </div>
            <div
              v-if="ethAmountResult"
              class="Input-EthResultBlock"
            >
              <p class="Global_Title Global_Title_h4 Input-ContentHeader">
                {{ $t('input_result_amount') }}
              </p>
              <div class="Input-EthResult">
                <p
                  id="TetherAmountResult"
                  class="Input-Copy-Link"
                >
                  {{ ethAmountResult }}
                </p>
                <img
                  class="Input-Copy"
                  src="../assets/images/CopyButton.svg"
                  alt=""
                  @click="copyTetherAmount"
                >
              </div>

              <p class="Global_Title Global_Title_h4 Input-ContentHeader">
                {{ $t('input_result_wallet') }}
              </p>
              <div class="Input-EthResult">
                <p
                  id="TetherWalletResult"
                  class="Input-Copy-Link"
                >
                  {{ ethExternalWallet }}
                </p>
                <img
                  class="Input-Copy"
                  src="../assets/images/CopyButton.svg"
                  alt=""
                  @click="copyTetherWallet"
                >
              </div>
              <div class="Input_list">
                <ol class="Input-InfoText Global_Text Global_Text_FontSize_M">
                  <li>
                    <span class="Input_list_Text">{{ $t('input_eth_text_1', { curr: 'Tether' }) }}</span>
                  </li>
                  <br>
                  <li>
                    <span class="Input_list_Text">{{ $t('input_eth_text_2', { curr: 'Tether' }) }}</span>
                  </li>
                  <br>
                  <li>
                    <span class="Input_list_Text"><b>{{ $t('input_eth_text_3', { curr: 'Tether' }) }}</b> {{ $t('input_eth_text_4', { curr: 'Tether' }) }}</span>
                  </li>
                  <br>
                  <li>
                    <span class="Input_list_Text">{{ $t('input_eth_text_5', { curr: 'Tether' }) }}</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div
            v-if="flagBlockShow === 'lion'"
            class="Input-EthBlock"
          >
            <div class="ce-input-eth-input">
              <ce-text-field
                :label="$t('input_write_amount')"
                :model-value="lionAmount"
                type="text"
                class="ce-input-lion-amount"
                :placeholder="$t('input_amount_placeholder')"
                @update:model-value="onInputLion"
              />
              <ce-text-field
                v-model.trim="ethWallet"
                type="text"
                class="ce-input-eth-wallet"
                :placeholder="$t('input_address_placeholder')"
                :label="$t('input_eth_transfer_from')"
              />
              <button
                v-if="lionEndOperation"
                class="Input_ButtonNex_Mob Input_Button_Next Global_Button Global_Button_FontSize_S Tether_Request"
                @click="requestEth"
              >
                {{ $t('input_next') }}
              </button>
            </div>
            <div
              v-if="ethAmountResult"
              class="Input-EthResultBlock"
            >
              <p class="Global_Title Global_Title_h4 Input-ContentHeader">
                {{ $t('input_result_amount') }}
              </p>
              <div class="Input-EthResult">
                <p
                  id="LionAmountResult"
                  class="Input-Copy-Link"
                >
                  {{ ethAmountResult }}
                </p>
                <img
                  class="Input-Copy"
                  src="../assets/images/CopyButton.svg"
                  alt=""
                  @click="copyLionAmount"
                >
              </div>

              <p class="Global_Title Global_Title_h4 Input-ContentHeader">
                {{ $t('input_result_wallet') }}
              </p>
              <div class="Input-EthResult">
                <p
                  id="LionWalletResult"
                  class="Input-Copy-Link"
                >
                  {{ ethExternalWallet }}
                </p>
                <img
                  class="Input-Copy"
                  src="../assets/images/CopyButton.svg"
                  alt=""
                  @click="copyLionWallet"
                >
              </div>
              <div class="Input_list">
                <ol class="Input-InfoText Global_Text Global_Text_FontSize_M">
                  <li>
                    <span class="Input_list_Text">{{ $t('input_eth_text_1', { curr: 'Tether' }) }}</span>
                  </li>
                  <br>
                  <li>
                    <span class="Input_list_Text">{{ $t('input_eth_text_2', { curr: 'Tether' }) }}</span>
                  </li>
                  <br>
                  <li>
                    <span class="Input_list_Text"><b>{{ $t('input_eth_text_3', { curr: 'Tether' }) }}</b> {{ $t('input_eth_text_4', { curr: 'Tether' }) }}</span>
                  </li>
                  <br>
                  <li>
                    <span class="Input_list_Text">{{ $t('input_eth_text_5', { curr: 'Tether' }) }}</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div
            v-if="flagBlockShow === 'genericFiat'"
            class="Input-FiatBlock"
          >
            <h1>FIAT</h1>
            <div class="">
              <div class="">
                Bank
              </div>
              <div class="">
                Paysera LY, UAB
              </div>
            </div>
            <div class="">
              <div class="">
                Bank address
              </div>
              <div class="">
                Pilates pr. 16, Вильнюс, LT-04352, Литва
              </div>
            </div>
            <div class="">
              <div class="">
                SWIFT
              </div>
              <div class="">
                EVIULT21XXX
              </div>
            </div>
            <div class="">
              <div class="">
                Company
              </div>
              <div class="">
                CryptoEnter OU
              </div>
            </div>
            <div class="">
              <div class="">
                Company address
              </div>
              <div class="">
                Estonia, Tallinn, Lenina 77
              </div>
            </div>
            <div class="">
              <div class="">
                Account
              </div>
              <div class="">
                LT13 3500 0100 0578 4297
              </div>
            </div>
            <div class="">
              <div class="">
                Payment description
              </div>
              <div class="">
                “transfer of own funds to the account <span style="color: #ED6D6A">{{ userName }}</span>”
              </div>
            </div>
            <div class="">
              Important! Please send this amount for input!
            </div>
          </div>
          <template v-else-if="showConnectMetaMaskButton">
            <div
              v-if="showMetaMaskConnected"
              class="ce-input-metamask-connected"
            >
              <i
                class="pi pi-check-circle ce-input-metamask-connected__icon"
              />
              <div class="ce-input-metamask-connected__text">
                {{ $t('input_metamask_connected') }}
              </div>
            </div>
            <progress-spinner
              v-if="loadingMetaMask"
              class="ce-input-metamask-loader"
            />
            <div
              v-else
              class="ce-input-metamask-connect"
              @click="metaMaskButtonHandler"
            >
              {{ $t(metaMaskButtonText) }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <Confirm
      :flag-show="flagConfirm"
      @confirm-action="confirmed"
      @hide-confirm="hideConfirm"
    />
  </div>
</template>

<script>
import MetaMaskSDK from '@metamask/sdk'
import Confirm from '@/components/Confirm'
import 'vue-select/dist/vue-select.css'
import VueQrcode from 'vue-qrcode'
import { mapActions, mapGetters } from 'vuex'
import { controlledAmountInput } from '@/Methods/GlobalMethods'
import TokenSmartContract from '@/Services/TokenSmartContract'
import Web3Service from '@/Services/Web3Service'
import { ETH_CURRENCIES, ETH_TOKEN_CURRENCIES } from '@/constants/input'
import EthService from '@/Services/EthService'
import CeTextField from '@/components/common/CeTextField.vue'
import CeDropdown from '@/components/common/CeDropdown.vue'
import KycVariants from '@/components/Input/KycVariants.vue'

export default {
  components: {
    KycVariants,
    CeDropdown,
    CeTextField,
    Confirm,
    VueQrcode
  },
  data () {
    const ethereum = new MetaMaskSDK().getProvider()
    this.ethereum = ethereum
    if (ethereum) {
      this.web3Service = new Web3Service(ethereum)
    }
    return {
      // Флаги операций
      flagConfirm: false,
      lionEndOperation: true,
      flagBlockShow: '',
      alreadySentToMetaMask: false,
      // Глобальные данные пользователя
      MainWallet: '',
      MainAccount: '',
      // Переменные рабочего вида
      userName: localStorage.getItem('user_id'),
      currency: '',
      externalWallet: '',
      inputOperationAvailable: true,
      listCurrencyForOperation: [],
      listCurrencyLimitation: [],
      listWithdrawalCurrencies: [],
      // Перемнные для вида эфира
      ethAmount: '',
      ethAmountResult: '',
      ethExternalWallet: '',
      commissionPercentage: '',
      commissionCryptoenter: '',
      tetherAmount: '',
      tetherAmountResult: '',
      tetherExternalWallet: '',
      lionAmount: '',
      lionWallet: '',
      lionAmountResult: '',
      lionExternalWallet: '',
      ethWallet: '',
      loadingMetaMask: false,
      metaMaskConnected: false,
      rubAmount: ''
    }
  },
  computed: {
    ...mapGetters(['kycVerified', 'listCurrencyName']),
    currencyList () {
      return this.listCurrencyName
    },
    summaryCommissionPercentage () {
      return Number(this.commissionPercentage) + Number(this.commissionCryptoenter)
    },
    metaMaskButtonText () {
      return !this.metaMaskConnected ? 'input_metamask_connect' : 'input_metamask_send_via'
    },
    showConnectMetaMaskButton () {
      return window.ethereum
        && this.selectedIsEthCurrency
        && this.ethAmountResult
      // && (!this.selectedIsEthToken || this.ethWallet.toLowerCase() === this.web3Service.selectedAddress)
    },
    selectedIsEthCurrency () {
      return ETH_CURRENCIES.includes(this.currency)
    },
    selectedIsEthToken () {
      return ETH_TOKEN_CURRENCIES.includes(this.currency)
    },
    isWalletValid () {
      return this.ethWallet.match(/^0x[a-fA-F0-9]{40}$/gm)
    },
    showMetaMaskConnected () {
      return this.metaMaskConnected
    },
    isRub () {
      return this.currency === 'RUB'
    }
  },
  metaInfo: {
    title: 'Deposit'
  },
  methods: {
    ...mapActions([
      'getCommission'
    ]),
    getCommissionForOperation () {
      this.$store.dispatch('getCommission', 'DEPOSIT_FUNDS')
        .then(response => {
          this.commissionPercentage = response.commissionPercentage
          this.commissionCryptoenter = response.commissionCryptoenter
        })
    },
    onInputEth (val, event) {
      this.ethAmount = controlledAmountInput(event, this.ethAmount)
    },
    onInputTether (val, event) {
      this.tetherAmount = controlledAmountInput(event, this.tetherAmount, 0)
    },
    onInputLion (val, event) {
      this.lionAmount = controlledAmountInput(event, this.lionAmount, 0)
    },
    copyBtcWallet () {
      this.$CopyToClipboard(this.externalWallet.account_id)
      // this.$CopyData(document.getElementById('BTCWallet'))
    },
    copyTetherAmount () {
      this.$CopyToClipboard(this.ethAmountResult)
      // this.$CopyData(document.getElementById('TetherAmountResult'))
    },
    copyEthAmount () {
      this.$CopyToClipboard(this.ethAmountResult)
      // this.$CopyData(document.getElementById('ETHAmountResult'))
    },
    copyTetherWallet () {
      this.$CopyToClipboard(this.ethExternalWallet)
      // this.$CopyData(document.getElementById('TetherWalletResult'))
    },
    copyEthWallet () {
      this.$CopyToClipboard(this.ethExternalWallet)
      // this.$CopyData(document.getElementById('ETHWalletResult'))
    },
    copyLionAmount () {
      this.$CopyToClipboard(this.ethAmountResult)
      // this.$CopyData(document.getElementById('LionAmountResult'))
    },
    copyLionWallet () {
      this.$CopyToClipboard(this.ethExternalWallet)
      // this.$CopyData(document.getElementById('LionWalletResult'))
    },
    hideConfirm (data) {
      console.log('Отменяем')
      this.flagConfirm = data
      this.currency = ''
      this.flagBlockShow = ''
    },
    /**
     * Функция построения списка доступных для ввода валют
     */
    getOperationLimitations () {
      this.$store.commit('SetLoaderFlag', true)
      this.$store.dispatch('getOperationLimitations', 'DEPOSIT_FUNDS')
        .then(response => {
          this.listCurrencyLimitation = response
          response.forEach(element => {
            const currencyCode = this.$GetCurrencyCodeByName(element.currency_type)
            if (currencyCode) {
              this.listCurrencyForOperation.push(currencyCode)
            }
          })
        })
        .catch(err => {
          this.$notify({
            title: this.$t('error_title'),
            text: err,
            type: 'error'
          })
        })
        .finally(() => {
          this.$store.commit('SetLoaderFlag', false)
        })
    },
    /**
     * Функция изменения валюты
     * @param data
     */
    changeCurrency (data) {
      console.log({ data })
      this.lionEndOperation = true
      this.alreadySentToMetaMask = false
      this.loadingMetaMask = false
      this.metaMaskConnected = false
      this.metaMaskConnected = this.web3Service?.selectedAddress && this.ethereum.isConnected()
      if (data) {
        this.externalWallet = ''
        this.ethAmountResult = ''
        this.ethExternalWallet = ''
        this.lionAmount = ''
        this.lionWallet = ''
        this.lionAmountResult = ''
        this.lionExternalWallet = ''
        this.ethWallet = ''
        let inputAvailable = false
        this.listCurrencyLimitation.forEach(item => {
          if (item.currency_type === this.$GetCurrencyNameByCode(data)) {
            console.log({ item })
            if (item.is_enabled === 'true') {
              inputAvailable = true
              this.currency = data
              this.flagBlockShow = this.$GetCurrencyNameByCode(data)
              console.log(this.flagBlockShow)
              if (this.flagBlockShow === 'bitcoin') {
                this.createWallet()
              } else if (this.flagBlockShow === 'ethereum') {

              }
            }
          }
        })
        this.inputOperationAvailable = inputAvailable
      } else {
        this.externalWallet = ''
        this.ethAmountResult = ''
        this.ethExternalWallet = ''
        this.flagBlockShow = ''
      }
    },
    confirmed (userData) {
      let amountForSend = 0
      this.$store.commit('SetLoaderFlag', true)
      switch (this.flagBlockShow) {
        case 'bitcoin':
          this.$store.dispatch('createBitcoinExternalWallet', {
            key: userData.key,
            currency: this.$GetCurrencyNameByCode(this.currency),
            mainWallet: this.MainWallet
          })
            .then(response => {
              if (response.data.status === 200) {
                this.externalWallet = response.data.payload
              } else {
                this.$notify({
                  title: this.$t('error_title'),
                  text: this.$selectMessageText('En', 'error'),
                  type: 'error'
                })
                this.currency = ''
              }
            })
            .catch(() => {
              this.$notify({
                title: this.$t('error_title'),
                text: this.$selectMessageText('En', 'error'),
                type: 'error'
              })
              this.currency = ''
            })
            .finally(() => {
              this.$store.commit('SetLoaderFlag', false)
              this.flagConfirm = false
            })
          break
        case 'tether':
        case 'lion':
        case 'ethereum':
          this.flagConfirm = false
          switch (this.flagBlockShow) {
            case 'ethereum':
              amountForSend = this.ethAmount
              break
            case 'tether':
              amountForSend = this.tetherAmount
              break
            case 'lion':
              amountForSend = this.lionAmount
              break
          }
          this.$store.dispatch('createEthExternalWallet',
            {
              key: userData.key,
              currency: this.$GetCurrencyNameByCode(this.currency),
              amount: amountForSend,
              ethWallet: this.ethWallet
            })
            .then(async response => {
              if (response.data.status === 200) {
                const { data: { payload: { code_amount, wallet } } } = response
                this.ethAmountResult = code_amount
                this.ethExternalWallet = wallet
                this.lionEndOperation = false
              } else {
                this.$notify({
                  title: this.$t('error_title'),
                  text: this.$selectMessageText('En', 'error'),
                  type: 'error'
                })
                // this.currency = ''
              }
            })
            .catch(() => {
              this.$notify({
                title: this.$t('error_title'),
                text: this.$selectMessageText('En', 'error'),
                type: 'error'
              })
              this.currency = ''
            })
            .finally(() => {
              this.$store.commit('SetLoaderFlag', false)
            })
          break
      }
    },
    requestEth () {
      let amount
      if (this.flagBlockShow === 'tether') {
        amount = this.tetherAmount
        if (!this.isWalletValid) {
          this.$notify({
            title: this.$t('error_title'),
            text: this.$selectMessageText('En', 'notValidEthWallet'),
            type: 'error'
          })
          return
        }
      } else if (this.flagBlockShow === 'ethereum') {
        amount = this.ethAmount
      } else if (this.flagBlockShow === 'lion') {
        amount = this.lionAmount
        if (!this.isWalletValid) {
          this.$notify({
            title: this.$t('error_title'),
            text: this.$selectMessageText('En', 'notValidEthWallet'),
            type: 'error'
          })
          return
        }
      }

      if (!Number(amount)) {
        this.$notify({
          title: this.$t('error_title'),
          text: this.$selectMessageText('En', 'amountCannotEmpty'),
          type: 'error'
        })
        return
      }
      this.listCurrencyLimitation.forEach(item => {
        if (item.currency_type === this.$GetCurrencyNameByCode(this.currency)) {
          if (this.$NewValid({
            typeValidation: 'AmountLimitations',
            dataForValid: {
              amount: amount,
              minValue: item.min_value,
              scale: item.truncate_precision
            }
          })) {
            this.flagConfirm = true
          } else {
            this.$notify({
              title: this.$t('error_title'),
              text: 'Не попали в ограничения валюты',
              type: 'error'
            })
          }
        }
      })
    },

    createWallet () {
      this.$store.commit('SetLoaderFlag', true)
      this.$store.dispatch('getAllWalletsAndAccounts')
        .then(response => {
          let externalWalletFlag = false
          let walletList = response
          walletList.forEach(item => {
            if (item.wallet.type === 'MAIN') {
              item.accounts.forEach(accountInfo => {
                if (accountInfo.currency_type === this.$GetCurrencyNameByCode(this.currency)) {
                  this.externalWallet = accountInfo
                  externalWalletFlag = true
                }
              })
            }
          })
          if (!externalWalletFlag) {
            this.flagConfirm = true
          }
        })
        .catch(() => {
          this.$notify({
            title: this.$t('error_title'),
            text: this.$selectMessageText('En', 'error'),
            type: 'error'
          })
        })
        .finally(() => {
          this.$store.commit('SetLoaderFlag', false)
        })
    },
    metaMaskButtonHandler () {
      this.metaMaskConnected ? this.sendToMetaMask() : this.connectToMetaMask()
    },
    async connectToMetaMask () {
      try {
        this.loadingMetaMask = true
        await this.web3Service.connectToMetaMask()
        this.metaMaskConnected = true
      } catch (e) {
        console.log('Connection to MetaMask Failed')
      } finally {
        this.loadingMetaMask = false
      }
    },
    async sendToMetaMask () {
      try {
        this.loadingMetaMask = true
        const ethService = this.selectedIsEthToken
          ? new TokenSmartContract(this.currency, this.web3Service)
          : new EthService(this.web3Service)
        await ethService.transfer(this.ethExternalWallet, this.ethAmountResult)
        this.alreadySentToMetaMask = true
      } catch (err) {
        console.log(err)
      } finally {
        this.loadingMetaMask = false
      }
    },
    chainChangedHandler (chainId) {
      console.log([chainId])
      window.location.reload()
    },
    goToGpb () {
      if (!this.rubAmount) return
      const url = 'https://lt.pga.gazprombank.ru/pages?lang_code=RU&merch_id=D96D1A21DA7BDD7F5CAB&back_url_s=https://develop.cryptoenter.com/lk/input&back_url_f=https://develop.cryptoenter.com/lk/input&o.order_id=44223'
      window.location.replace(url)
    }
  },
  async mounted () {
    this.$store.commit('SetLoaderFlag', true)
    let walletInfo = this.$store.getters.GetWalletInfo
    this.MainWallet = walletInfo.wallet
    this.MainAccount = walletInfo.account
    this.getOperationLimitations()
    this.getCommission()
    // window.ethereum?.on('chainChanged', this.chainChangedHandler)
  },
  deactivated () {
    // window.ethereum?.removeListener('chainChanged', this.chainChangedHandler)
  }
}
</script>

<style lang="stylus">
@import "../styles/variable"
.alert-warning
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba

.alert
  position: relative;
  padding: .75rem 1.25rem;
  margin-top: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;

.Input_Eth_Wallet
  width 250px !important

.Tether_Request
  align-self flex-end

form
  padding-top: 0;

.Input
  &_list ol
    list-style-type: none;
    counter-reset: num;

  &_list li::before
    content: counter(num) '. ';
    counter-increment: num;
    color: Brend;

  &_list
    background-color: #ffffff;
    border: 1px solid #ffc600;
    border-radius: 4px;
    padding 20px

    &_Text
      margin-left 10px

  &-EthResultBlock
    margin: 50px 0 30px 0;

  &_Button_Next
    color: #fafafa;
    background-color: #ffc600;
    border: 2px solid #ffc600;
    padding: 3px 40px;
    height: 30px;
    border-radius: 3px;
    margin-left: 10px;

  &_Input_InputFormT
    border-color: #bfbfbf;
    border-style: solid;
    border-width: 1px;
    height: 30px;
    border-radius: 3px;
    padding: 0px 10px;
    outline: none;
    width: 210px;

  &_QRcod
    height: 148px;
    width: 148px;

  &-SelectBlock
    display flex
    justify-content space-between
    flex-wrap wrap
    width 100%
    align-items center
    padding-left: 5px

    form
      flex-grow 1

  &_MarginForm
    width: 180px;

  &_Burron
    height 36px
    margin-right 20px

  &_BorderBottom
    &_Top
      border-bottom 1px solid rgba(#ffc600)
      height 18px
      width 74%

    &_Bottom
      border-bottom 1px solid rgba(#ffc600)

  &_Position
    display flex

    &_Edge
      justify-content: space-between

  &_Section
    width: 48%;

  &_Title2
    margin-top 20px
    margin-bottom 20px

  &_Title
    margin-left 20px
    margin-top 20px
    margin-bottom 20px

    &_Total
      padding-right 20px

  &_Global_TitleSection
    margin-left 20px

  &_hr
    color #ff0000

  &_Form-InputHelp
    position: absolute;
    top: 7px;
    right: 8px;

  &_Form
    display flex
    border-radius: 3px;
    align-items flex-start
    flex-direction column

  &-BtcWalletBlock, &-BtcQrCodeBlock
    display flex
    justify-content flex-start

  &-BtcWalletBlock
    padding-left: 15px

  &-BtcQrCodeText
    padding-top: 15px

  &-BtcBlock
    width: 100%;

  &-BtcAnswer
    flex-wrap: wrap;

  &-Copy
    cursor pointer
    padding-bottom 10px

    &-Link
      margin-right: 10px
      padding-bottom 10px

  &-ContentHeader
    padding-left: 15px
    margin-bottom: 15px
    color Brend

  &-EthBlock
    padding-left: 5px
    width 100%
    margin-top: 16px;
    display: flex;
    flex-direction: column;

  &-EthInput
    display flex
    align-items center
    gap 5px

    label
      font-size 11px

  &-EthResult
    display flex
    align-items center
    padding-left: 15px;

    &:not(:last-child)
      margin-bottom: 30px

.styleSelectTest
  & .vs__search::placeholder, & .vs__dropdown-toggle, & .vs__dropdown-menu
    background: #dfe5fb;
    border: none;
    color: #394066;
    text-transform: lowercase;
    font-variant: small-caps;

@media screen and (max-width: 1024px)
  .Input
    &_MarginForm
      width: 150px;

@media screen and (max-width: 768px)
  .Input
    &-EthResultBlock
      margin: 10px 0 20px 0;

    &_Input_InputFormT
      width: 256px;

    &_Button_Next
      margin-left 0

    &_Form
      flex-wrap: wrap;

    &-SelectBlock
      width: 100%;

    &-SenderSelect
      width: 100px;

    &-SelectBlock
      padding-left: 0px;

    &-BtcWalletBlock, &-BtcQrCodeBlock
      flex-wrap: wrap;

    &-ContentHeader
      padding-left: 0
      margin-top: 34px;

    &-BtcWalletBlock
      padding-left: 0

    &-EthInput
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 0px;

    &-EthBlock
      padding-left 0

    &_ButtonNex_Mob
      width 100%
      height 40px
      margin-top 16px

    &_InputBorder
      margin-top 16px

    &-EthResult
      padding-left: 0;

    &-EthResult
      flex-wrap: wrap;
      word-break: break-all;

    &_MarginForm
      width: 150px;

@media screen and (max-width: 414px)
  .Input
    &_Button_Next
      margin-left 0

    &_Input_InputFormT
      width: 224px;

    &-EthResultBlock
      margin: 10px 0 20px 0;

    &_Form
      flex-wrap: wrap;

    &-SelectBlock
      width: 100%;

    &-SenderSelect
      width: 224px;

    &-SelectBlock
      //padding-left: 5px;

    &-BtcWalletBlock, &-BtcQrCodeBlock
      flex-wrap: wrap;

    &-ContentHeader
      padding-left: 0
      margin-top: 34px;

    &-BtcWalletBlock
      padding-left: 0

    &-EthInput
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 0px;

    &-EthBlock
      padding-left 0

    &_ButtonNex_Mob
      width 100%
      height 40px
      margin-top 16px

    &_InputBorder
      margin-top 0px

    &-EthResult
      padding-left: 0;

    &-EthResult
      flex-wrap: wrap;
      word-break: break-all;

    &-Copy

      &-Link
        margin-right: 5
        font-size 10px
@media screen and (max-width: 375px)
  .Input
    &_Form
      flex-wrap: wrap;

    &-SelectBlock
      width: 100%;

    &-SenderSelect
      width: 184px

    &-BtcWalletBlock, &-BtcQrCodeBlock
      flex-wrap: wrap;

    &-ContentHeader
      padding-left: 0
      margin-top: 34px;

    &-BtcWalletBlock
      padding-left: 0

    &-EthInput
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 0px;

    &-EthBlock
      padding-left 0

    &_ButtonNex_Mob
      width 100%
      height 40px
      margin-top 16px

    &-EthResult
      padding-left: 0;

    &-EthResult
      flex-wrap: wrap;
      word-break: break-all;

    &_Input
      &_InputFormT
        width: 184px;

    &_MarginForm
      width: 120px;
</style>

<style lang="scss">
.ce-input {
  &-title {
    display: none;
    @include forDesktop {
      display: block;
    }
  }

  &-currency-select, &-eth-amount, &-lion-amount, &-eth-wallet, &-tether-amount {
    width: 100%;
  }

  &-eth-input {
    @include forDesktop_v2 {
      display: grid;
      grid-gap: 10px;
    }
    @include forDesktop1280_v2 {
      grid-template-columns: 1fr 1fr;
    }
    .ce-input {
      &-lion-amount, &-tether-amount, &-eth-btn {
        align-self: flex-end;
      }
    }
  }

  &-metamask {
    &-loader {
      width: 30px;
      height: 30px;
    }

    &-complete {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 400px;
      width: 100%;

      &__icon {
        font-size: 48px;
        color: $Success;
      }

      &__text {
        font-size: 24px;
        line-height: 28px;
        margin-top: 20px;
        text-align: center;
      }
    }

    &-connect {
      background: $Brand;
      cursor: pointer;
      border: none;
      height: 34px;
      font-family: Geometria, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: $White;
      border-radius: 3px;
      padding: 0 20px;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      justify-content: center;

      &:hover {
        background-color: $Success;
        color: $White;
        transition: 0.4s;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
      }
    }

    &-connected {
      display: flex;
      gap: 5px;
      padding: 5px 0;

      &__icon {
        color: $Success;
      }

      &__text {
        color: $Success;
      }
    }
  }
  .rub-input {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    margin-top: 32px;
    min-width: 260px;
    div:first-child {
      min-width: 150px;
    }
  }
}
</style>
