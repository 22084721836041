export const FORM_TYPE_NAMES = {
  INTERNAL: 'Internal',
  FIAT: 'AutoexchangeCash',
  ASSET: 'Asset',
  BILL: 'AutoexchangeBill'
}

export const FORM_TYPE_LABELS = {
  [FORM_TYPE_NAMES.FIAT]: 'Наличные получить/отдать',
  [FORM_TYPE_NAMES.BILL]: 'Оплатить счёт',
  [FORM_TYPE_NAMES.INTERNAL]: 'Валюты в Cryptoenter',
  [FORM_TYPE_NAMES.ASSET]: 'Товары и услуги'
}

export const FORM_TYPES = Object.values(FORM_TYPE_NAMES).map(value => {
  return { value, label: FORM_TYPE_LABELS[value] }
})

export const EXTERNAL_FORM_TYPES = FORM_TYPES.filter(i => ![FORM_TYPE_NAMES.INTERNAL, FORM_TYPE_NAMES.ASSET].includes(i.value))

export const PAYMENT_TYPE_NAMES = {
  CASH: 'Cash',
  CARD_AMOUNT: 'CardAmount',
  // ACCOUNT_AMOUNT: 'AccountAmount',
  FOREIGN_ACCOUNT: 'AccountAmount'
}

export const PAYMENT_TYPES_LABEL = {
  [PAYMENT_TYPE_NAMES.CASH]: 'Наличные',
  [PAYMENT_TYPE_NAMES.CARD_AMOUNT]: 'Перевод на карту',
  // [PAYMENT_TYPE_NAMES.ACCOUNT_AMOUNT]: 'Перевод на счёт',
  [PAYMENT_TYPE_NAMES.FOREIGN_ACCOUNT]: 'Перевод на счёт',
}

export const PAYMENT_TYPES_ICON = {
  [PAYMENT_TYPE_NAMES.CASH]: 'cash.svg',
  [PAYMENT_TYPE_NAMES.CARD_AMOUNT]: 'bank-card.svg',
  // [PAYMENT_TYPE_NAMES.ACCOUNT_AMOUNT]: 'invoice.svg',
  [PAYMENT_TYPE_NAMES.FOREIGN_ACCOUNT]: 'invoice.svg',
}

export const PAYMENT_TYPES = Object.values(PAYMENT_TYPE_NAMES).map(paymentType => {
  return {
    label: PAYMENT_TYPES_LABEL[paymentType],
    value: paymentType,
    icon: PAYMENT_TYPES_ICON[paymentType]
  }
})

export const SELECTOR_VALUE_NAMES = {
  BUY: 'BUY',
  SELL: 'SELL'
}

export const SELECTOR_VALUE_LABELS = {
  [SELECTOR_VALUE_NAMES.BUY]: 'p2p_selector_buy',
  [SELECTOR_VALUE_NAMES.SELL]: 'p2p_selector_sell'
}

export const SELECTOR_VALUES = [
  {
    value: SELECTOR_VALUE_NAMES.BUY,
    label: 'p2p_selector_buy'
  },
  {
    value: SELECTOR_VALUE_NAMES.SELL,
    label: 'p2p_selector_sell'
  }
]

export const DEAL_STATUS_NAME = {
  WAIT_ACCEPT: 'WaitAccept',
  WAIT_PARTICIPANT_ACCEPT: 'WaitParticipantAccept',
  OPEN: 'Open',
  CLOSED: 'Closed',
  PROCESSED: 'Processed',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected'
}

export const COMMON_DEAL_STATUS_NAMES = {
  OPENED: 'Open',
  CLOSED: 'Closed',
  PROCESSED: 'Processed',
  CANCELED: 'CANCELED'
}

export const DEAL_STATUS_NAME_FIAT_CASH = {
  ...COMMON_DEAL_STATUS_NAMES,
  PAYMENT_TYPE_ACCEPTED_CASH: 'CashPaymentTypeAccepted',
  TOKEN_ACCEPTED: 'TokenAccepted',
  ADDRESS_ACCEPTED: 'AddressAccepted',
  HANDED_OVER_CASH: 'HandOverCash',
  GOT_CASH: 'GotCash',
}

const {
  PAYMENT_TYPE_ACCEPTED_CASH,
  TOKEN_ACCEPTED,
  ADDRESS_ACCEPTED,
  HANDED_OVER_CASH,
  GOT_CASH,
} = DEAL_STATUS_NAME_FIAT_CASH

export const DEAL_STATUS_NAME_FIAT_ACCOUNT = {
  ...COMMON_DEAL_STATUS_NAMES,
  PAYMENT_TYPE_ACCEPTED_ACCOUNT: 'TransferPaymentTypeAccepted',
  CASH_RECEIPT_LOADED: 'CheckLoaded',
  TRANSFER_RECEIVED: 'TransferReceived',
}

const {
  PAYMENT_TYPE_ACCEPTED_ACCOUNT,
  CASH_RECEIPT_LOADED,
  TRANSFER_RECEIVED,
} = DEAL_STATUS_NAME_FIAT_ACCOUNT

export const DEAL_STATUS_NAME_BILL = {
  ...COMMON_DEAL_STATUS_NAMES,
  AGENCY_AGREEMENT_ACCEPTED: 'AgencyAgreementAccepted',
  BILL_PAID: 'BillPaid',
  BILL_FUNDS_RECEIVED: 'BillFundsReceived',
  ACT_ACCEPTED: 'ActAccepted'
}

const {
  AGENCY_AGREEMENT_ACCEPTED,
  BILL_PAID,
  BILL_FUNDS_RECEIVED,
  ACT_ACCEPTED,
} = DEAL_STATUS_NAME_BILL

export const DEAL_STATUS_LABEL = {
  // для всех
  [COMMON_DEAL_STATUS_NAMES.OPENED]: 'Открыта',
  [COMMON_DEAL_STATUS_NAMES.CLOSED]: 'Закрыта',
  // для типа FIAT, когда способ оплаты CASH
  [PAYMENT_TYPE_ACCEPTED_CASH]: 'Способ оплаты подтвержден',
  [TOKEN_ACCEPTED]: 'Токен загружен',
  [ADDRESS_ACCEPTED]: 'Адрес подтвержден',
  [HANDED_OVER_CASH]: 'Наличные переданы',
  [GOT_CASH]: 'Наличные получены',
  // для типа FIAT, когда способ оплаты На карту/По номеру счета
  [PAYMENT_TYPE_ACCEPTED_ACCOUNT]: 'Способ оплаты подтвержден',
  [CASH_RECEIPT_LOADED]: 'Платеж отправлен',
  [TRANSFER_RECEIVED]: 'Платеж получен',
  // для типа BILL
  [AGENCY_AGREEMENT_ACCEPTED]: 'Документы загружены',
  [BILL_PAID]: 'Счет оплачен',
  [BILL_FUNDS_RECEIVED]: 'Деньги пришли',
  [ACT_ACCEPTED]: 'Акт выполненных работ загружен',
  ARBITRATION: 'Арбитраж'
}

export const OFFER_STATUS_NAME = {
  OPEN: 'Open',
  CLOSED: 'Closed'
}

// export const DEAL_STATUS_LABEL = {
//   [DEAL_STATUS_NAME.WAIT_ACCEPT]: 'Ожидает вашего подтверждения',
//   [DEAL_STATUS_NAME.WAIT_PARTICIPANT_ACCEPT]: 'Ожидает подтверждения другим пользователем',
//   [DEAL_STATUS_NAME.OPEN]: 'Открыта',
//   [DEAL_STATUS_NAME.CLOSED]: 'Закрыта',
//   [DEAL_STATUS_NAME.ACCEPTED]: 'Подтверждена',
//   [DEAL_STATUS_NAME.REJECTED]: 'Отменена',
// }

export const PAYMENT_REASON_NAMES = {
  PRODUCT: 'PRODUCT',
  SERVICE: 'SERVICE',
}

export const PAYMENT_REASON_LABELS = {
  [PAYMENT_REASON_NAMES.SERVICE]: 'За услуги',
  [PAYMENT_REASON_NAMES.PRODUCT]: 'За товар'
}

export const PAYMENT_REASONS = Object.values(PAYMENT_REASON_NAMES).map(paymentReason => {
  return {
    label: PAYMENT_REASON_LABELS[paymentReason],
    value: paymentReason
  }
})

export const DEAL_ROLE_NAMES = {
  FIAT_CASH_BUYER: 'FIAT_CASH_BUYER',
  FIAT_CASH_SELLER: 'FIAT_CASH_SELLER',
  FIAT_ACCOUNT_BUYER: 'FIAT_ACCOUNT_BUYER',
  FIAT_ACCOUNT_SELLER: 'FIAT_ACCOUNT_SELLER',
  BILL_BUYER: 'BILL_BUYER',
  BILL_SELLER: 'BILL_SELLER',
}

export const RULE_TYPE_NAMES = {
  TRANSFER: 'Transfer',
  CASH: 'Cash',
  BILL: 'Bill'
}

export const MKTU = {
  '1': {
    'desc': 'Химические продукты, для промышленных, научных целей и сельского хозяйства',
    'img': require('@/assets/images/mktu/01_mktu_1.png'),
  },
  '2': {
    'desc': 'Вещества красящие и средства защитные от коррозии. К классу относятся:\nкраски, лаки',
    'img': require('@/assets/images/mktu/2_1_1.jpg'),
  },
  '3': {
    'desc': 'Препараты туалетные нелечебные, для уборки, гигиенические, косметика, бады',
    'img': require('@/assets/images/mktu/3_1_1.jpg'),
  },
  '4': {
    'desc': 'Масла и смазки технические, топлива и материалы осветительные, свечи и фитили',
    'img': require('@/assets/images/mktu/4_1_1.jpg'),
  },
  '5': {
    'desc': 'Фармацевтические и прочие препараты для медицинских или ветеринарных целей',
    'img': require('@/assets/images/mktu/5_1_1.jpg'),
  },
  '6': {
    'desc': 'Необработанные и частично обработанные обычные металлы и руды, и изделия из них',
    'img': require('@/assets/images/mktu/6_1_1.jpg'),
  },
  '7': {
    'desc': 'Машины, станки и двигатели.\nНапример, части двигателей, стартеры, глушители и цилиндры',
    'img': require('@/assets/images/mktu/7_1_1.jpg'),
  },
  '8': {
    'desc': 'Орудия и инструменты ручные, предназначенные для сверления, обработки, резания и бурения',
    'img': require('@/assets/images/mktu/8_1_1.jpg'),
  },
  '9': {
    'desc': 'Инструменты научные или исследовательские, оборудование для ИТ технологий',
    'img': require('@/assets/images/mktu/9_1_1.jpg'),
  },
  '10': {
    'desc': 'Приборы медицинские, изделия хирургические, стоматологические,  ветеринарные',
    'img': require('@/assets/images/mktu/10_1_1.jpg'),
  },
  '11': {
    'desc': 'Устройства для контроля состояния среды, освещения, приготовления пищи, охлаждения',
    'img': require('@/assets/images/mktu/11_1_1.jpg'),
  },
  '12': {
    'desc': 'В основном, аппараты и машины для перевозки людей или грузов по земле, воздуху и воде',
    'img': require('@/assets/images/mktu/12_1_1.jpg'),
  },
  '13': {
    'desc': 'Оружие огнестрельное и средства пиротехнические. Ракеты сигнальные, \nпистолеты, \nспреи',
    'img': require('@/assets/images/mktu/13_1_1.jpg'),
  },
  '14': {
    'desc': 'Класс включает металлы благородные и некоторые изделия из них, ювелирные, бижутерия и часы',
    'img': require('@/assets/images/mktu/14_1_1.jpg'),
  },
  '15': {
    'desc': 'Музыкальные инструменты, их части и аксессуары. Инструменты музыкальные механические',
    'img': require('@/assets/images/mktu/15_1_1.jpg'),
  },
  '16': {
    'desc': 'Бумага, картон и некоторые изделия из этих материалов, изделия конторские',
    'img': require('@/assets/images/mktu/16_1_1.jpg'),
  },
  '17': {
    'desc': 'Электро-, тепло- и звуко- изоляционные материалы и пластмассы частично обработанные',
    'img': require('@/assets/images/mktu/17_1_1.jpg'),
  },
  '18': {
    'desc': 'Кожа и имитация кожи, некоторые изделия из этих материалов, сумки, чемоданы',
    'img': require('@/assets/images/mktu/18_mktu.jpg'),
  },
  '19': {
    'desc': 'Материалы строительные неметаллические. Например, балки, доски, панели',
    'img': require('@/assets/images/mktu/19_mktu.jpg'),
  },
  '20': {
    'desc': 'Мебель, ее части и изделия из дерева, пробки, камыша, тростника, ивы, рога, кости, слоновой кости',
    'img': require('@/assets/images/mktu/20_mktu.jpg'),
  },
  '21': {
    'desc': 'Приспособления и устройства домашние или кухонные, малогабаритные, ручные',
    'img': require('@/assets/images/mktu/21_mktu.jpg'),
  },
  '22': {
    'desc': 'Полотно и материалы для парусов, изделия веревочно-канатные, текстиль. Канаты, веревки',
    'img': require('@/assets/images/mktu/22_mktu.jpg'),
  },
  '23': {
    'desc': 'В основном, нити из натуральных или синтетических материалов для производства текстиля',
    'img': require('@/assets/images/mktu/23_mktu.jpg'),
  },
  '24': {
    'desc': 'Ткани и тканевые покрывала для бытового использования. Белье, наволочки, салфетки',
    'img': require('@/assets/images/mktu/24_mktu.jpg'),
  },
  '25': {
    'desc': 'Класс включает предметы одежды для людей.\nДетали одежды, обуви и головных уборов, например, манжеты',
    'img': require('@/assets/images/mktu/25_mktu.jpg'),
  },
  '26': {
    'desc': 'Изделия галантерейные и басонные, волосы и украшения для волос, изделия для украшения',
    'img': require('@/assets/images/mktu/26_mktu.jpg'),
  },
  '27': {
    'desc': 'Включает, в основном, изделия, предназначенные для покрытия готовых полов или стен',
    'img': require('@/assets/images/mktu/27_mktu.jpg'),
  },
  '28': {
    'desc': 'Игрушки, аппараты игровые, оборудование спортивное, изделия для развлечения и шуток',
    'img': require('@/assets/images/mktu/28_mktu.jpg'),
  },
  '29': {
    'desc': 'Класс включает продовольственные товары животного происхождения, овощи и огородные продукты',
    'img': require('@/assets/images/mktu/29_mktu.jpg'),
  },
  '30': {
    'desc': 'Класс включает, в основном, продукты растительные пищевые для потребления или консервирования',
    'img': require('@/assets/images/mktu/30_mktu.jpg'),
  },
  '31': {
    'desc': 'Класс включает продукты земледелия и моря, не подвергнутые никакой обработке для потребления',
    'img': require('@/assets/images/mktu/31_mktu.jpg'),
  },
  '32': {
    'desc': 'Класс включает, в основном, напитки безалкогольные, а также пиво, напитки освежающие безалкогольные',
    'img': require('@/assets/images/mktu/32_mktu.jpg'),
  },
  '33': {
    'desc': 'В основном, напитки алкогольные, эссенции и экстракты,\nвина,\nсидр, \nспирты и ликеры.',
    'img': require('@/assets/images/mktu/33_mktu.jpg'),
  },
  '34': {
    'desc': 'Табак и предметы, используемые для курения, а также некоторые аксессуары и емкости',
    'img': require('@/assets/images/mktu/34_mktu.jpg'),
  },
  '35': {
    'desc': 'Услуги, с целью: помощь в эксплуатации или управлении коммерческим предприятием',
    'img': require('@/assets/images/mktu/35_mktu.jpg'),
  },
  '36': {
    'desc': 'Услуги, связанные с банковскими и финансовыми операциями, услуги по фин. оценке',
    'img': require('@/assets/images/mktu/36_mktu.jpg'),
  },
  '37': {
    'desc': 'Услуги в области строительства, услуги по реставрации объектов в их первоначальном виде',
    'img': require('@/assets/images/mktu/37_mktu.jpg'),
  },
  '38': {
    'desc': 'Услуги связи. Возможность одному лицу установить связь с другим, вещание и передача данных',
    'img': require('@/assets/images/mktu/38_mktu.jpg'),
  },
  '39': {
    'desc': 'Услуги перевозки железнодорожным, автомобильным, водным, воздушным транспортом',
    'img': require('@/assets/images/mktu/39_mktu.jpg'),
  },
  '40': {
    'desc': 'Услуги хим. или мех. обработки, производство неорганических веществ или органических',
    'img': require('@/assets/images/mktu/40_mktu.jpg'),
  },
  '41': {
    'desc': 'Все формы обучения и профессиональной подготовки, услуги развлечения и отдыха',
    'img': require('@/assets/images/mktu/41_mktu.jpg'),
  },
  '42': {
    'desc': 'Услуги в отношении теоретических и практических аспектов сложных областей науки',
    'img': require('@/assets/images/mktu/42_mktu.jpg'),
  },
  '43': {
    'desc': 'Услуги, связанные с приготовлением еды и напитков, услуги по предоставлению жилья',
    'img': require('@/assets/images/mktu/43_mktu.jpg'),
  },
  '44': {
    'desc': 'Медицинское обслуживание, альтернативная медицина, красота и здоровье',
    'img': require('@/assets/images/mktu/44_mktu.jpg'),
  },
  '45': {
    'desc': 'Услуги оказываемые юристами, адвокатами индивидуальным лицам, группам или учреждениям',
    'img': require('@/assets/images/mktu/45_mktu.jpg'),
  }
}

const MKTU_DESC = {
  ru: 'Международная классификация товаров и услуг – создана для удобства пользования и подбора разделенная по определенным классам - товары (с 1 по 34 класс), услуги (с 35 по 45 класс), принятая в рамках Ниццкого соглашения и действующая во всех странах мира (ВОИС).' +
    'Товары и услуги разделили по группам по совокупности критериев, принципу родственности и схожести.' +
    'Товары — по функциям и назначению, услуги — по направлению деятельности.',
  en: 'International classification of goods and services - created for ease of use and selection, divided into certain classes - goods (from 1 to 34 classes), services (from 35 to 45 classes), adopted within the framework of the Nice Agreement and valid in all countries of the world (WIPO).' +
    'Goods and services were divided into groups according to a set of criteria, the principle of relatedness and similarity.' +
    'Goods - by function and purpose, services - by area of activity.',
}
