import axios from 'axios'
import { FORM_TYPE_NAMES, FORM_TYPES, SELECTOR_VALUE_NAMES } from '@/constants/P2P'
import { AE_OPERATION_TYPE_NAMES } from '@/constants/auto-exchange'
import {
  getAdditionalDealInfoFromWs,
  isMKTUNumbersMatch,
  makeP2PAssetDeals,
  makeP2PBillDeals,
  makeP2PDeals
} from '@/utils/p2p'
import { AutoexchangeSignatures } from '@/modules/Signatures'
import DealModel from '@/models/P2P/DealModel'
import P2POfferModel from '@/models/P2P/P2POfferModel'
import P2PDealStatus from '@/models/P2P/P2PDealStatus'
import { notify } from '@kyvg/vue3-notification'
import P2PDealPaymentTypeAccept from '@/models/P2P/P2PDealPaymentTypeAccept'
import { deepCopy, downloadFile, getFileName } from '@/utils/common'
import P2PDealAddressModel from '@/models/P2P/P2PDealAddressModel'

const mockOffers = [
  // BUY
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '100',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '4',
    owned_value: {
      amount: '10',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '1',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '1000',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '100',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '5',
    owned_value: {
      amount: '15',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '2',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '1500',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '110',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '6',
    owned_value: {
      amount: '10',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '1',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '1100',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '90',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '7',
    owned_value: {
      amount: '20',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '1',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '1800',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
  // SELL
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '50',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '8',
    owned_value: {
      amount: '1100',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '1',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '22',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '100',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '9',
    owned_value: {
      amount: '1100',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '1',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '11',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
  {
    city: 'Moscow',
    country: 'Russia',
    exchange_rate: '100',
    lock_txs_op_id: 'string',
    match_amount_step: '0',
    min_match_amount: '10',
    number: 0,
    operation_id: '10',
    owned_value: {
      amount: '1500',
      asset: {
        name: 'string',
      },
      currency: 'RUB',
      type: 'Internal',
    },
    requisites: 'string',
    response_offer_op_id: '1',
    status: 'Open',
    timestamp: 0,
    user_account_info: {
      account_id: '123123123123',
      user_id: '123123123123',
      wallet_id: '123123123123',
    },
    wanted_value: {
      amount: '15',
      asset: {
        name: 'string',
      },
      currency: 'tether',
      fiat_types: ['CardAmount'],
      type: 'Internal',
    },
  },
]

const mockFiatSteps = {
  RUB: '5000',
  USD: '100',
  EUR: '100'
}

const mockFiatBanknotes = {
  RUB: [5, 10, 50, 100, 500, 1000, 5000],
  USD: [1, 2, 5, 10, 20, 50, 100],
  EUR: [5, 10, 20, 50, 100, 200]
}

const state = () => ({
  p2pOffers: [],
  p2pDealsMap: {},
  p2pUserOffers: [],
  p2pExtendedDealsMap: {},
  offersByType: {},
  fiatSteps: mockFiatSteps,
  fiatBanknotes: mockFiatBanknotes,
  p2pOfferType: SELECTOR_VALUE_NAMES.BUY,
  p2pOperationType: FORM_TYPES[3],
  ownedCurrency: 'AEDCash',
  wantedCurrency: 'RUB',
  p2pSettingsByCurrency: {},
  offerForEnterDeal: null,
  currentDeal: null,
  userOrders: [],
  p2pTypeDividers: [],
  p2pDealRequisites: [],
  p2pRuleType: null,
  sendDocumentAction: () => {},
  scrollToAcceptedDoc: () => {},
  sendAddressAction: () => {},
  scrollToAcceptedAddress: () => {},
  dealStatusRules: [],
  offersFilters: {},
  mktuClassObject: {},
  displayingOffersCount: 5
})

const mutations = {
  setDisplayingOffersCount (state, val) {
    state.displayingOffersCount = val
  },
  setMKTUClassObject (state, data) {
    state.mktuClassObject = data
  },
  setP2POffersFilters (state, data) {
    state.offersFilters = data
  },
  setDealStatusRules (state, data) {
    state.dealStatusRules = data
  },
  setScrollToAcceptedDoc (state, method) {
    state.scrollToAcceptedDoc = method
  },
  setSendDocumentAction (state, method) {
    state.sendDocumentAction = method
  },
  setScrollToAcceptedAddress (state, method) {
    state.scrollToAcceptedAddress = method
  },
  setSendAddressAction (state, method) {
    state.sendAddressAction = method
  },
  setP2PRuleType (state, val) {
    state.p2pRuleType = val || null
  },
  setP2PDealRequisites (state, data) {
    state.p2pDealRequisites = data
  },
  setP2PTypeDividers (state, data) {
    state.p2pTypeDividers = data
  },
  setP2POperationType (state, val) {
    state.p2pOperationType = val
  },
  setP2PDealsMap (state, data) {
    state.p2pDealsMap = data
  },
  setP2PUsersOffers (state, data) {
    state.p2pUserOffers = data
  },
  setP2PExtendedDealsMap (state, data) {
    state.p2pExtendedDealsMap = data
  },
  setP2POfferType (state, val) {
    state.p2pOfferType = val
  },
  setOwnedCurrency (state, val) {
    state.ownedCurrency = val
  },
  setWantedCurrency (state, val) {
    state.wantedCurrency = val
  },
  setP2PSettingsByCurrency (state, data) {
    state.p2pSettingsByCurrency = data
  },
  setFiatSteps (state, data) {
    state.fiatSteps = data
  },
  setFiatBanknotes (state, data) {
    state.fiatBanknotes = data
  },
  setOfferForEnterDeal (state, data) {
    state.offerForEnterDeal = data
  },
  setCurrentDeal (state, data) {
    state.currentDeal = data
  },
  updateCurrentDealStatus (state, newStatus) {
    state.currentDeal.status = newStatus
  },
  setP2POffers (state, data) {
    state.p2pOffers = data
  },
  setP2POffersByType (state, data) {
    state.offersByType = data
  },
  setP2PUserOrders (state, data) {
    state.userOrders = data
  },
  updateP2PDeal (state, data) {
    state.p2pDealsMap[data.operation_id] = data
  },
  updateP2POffers (state, offer) {
    state.p2pOffers.push(offer)
  },
  removeP2POffer (state, operationId) {
    const idx = state.p2pOffers.findIndex(i => i.operation_id === operationId)
    if (~idx) state.p2pOffers.splice(idx, 1)
  },
}

const actions = {
  calculateOffersFilters ({ state, rootGetters, commit }) {
    const result = {}
    Object.keys(state.mktuClassObject).forEach(className => {
      if (!result.mktu) result.mktu = []
      if (state.mktuClassObject[className]?.length === rootGetters.getMKTUCodesByClass(className)?.length) {
        result.mktu.push(className)
      } else {
        result.mktu.push(...state.mktuClassObject[className])
      }
    })
    commit('setP2POffersFilters', result)
  },
  async fetchP2PSettings ({ rootState, commit }) {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${rootState.backEndAddress}/admin/p2p-currency-params`
      })
      if (data?.status === 200) {
        const fiatSteps = {}
        const settings = {}
        const fiatBanknotes = {}
        data.payload.forEach(item => {
          const currency = rootState.tokensNameMap[item.currency]?.code
          settings[currency] = item
          fiatSteps[currency] = item.step
          fiatBanknotes[currency] = item.banknotes
        })
        commit('setP2PSettingsByCurrency', settings)
        commit('setFiatSteps', fiatSteps)
        commit('setFiatBanknotes', fiatBanknotes)
      }
    } catch (err) {
      console.log({ err })
    }
  },
  async fetchP2POffers ({ rootState, state, commit, getters, rootGetters }, filters = {}) {
    const pairPath = rootGetters.getCurrentP2PPair?.pair?.split('/').map(i => rootState.tokensCodeMap[i]?.name).join('/')
    if (!pairPath) return
    const additionalFilters = deepCopy(filters)
    if (additionalFilters.mktu) additionalFilters.mktu = additionalFilters.mktu.join(',')
    try {
      const url = `${rootState.backEndAddress}/autoexchange/offers/${pairPath}`
      const { data } = await axios({
        method: 'GET',
        url,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        params: {
          cancelled: false,
          opType: state.p2pOperationType.value,
          ...additionalFilters
        }
      })
      if (data.status !== 200) {
        throw Error('P2P Offers! Something went wrong')
      }
      const { payload: offers } = data
      offers.sort((a, b) => a.timestamp - b.timestamp)
      commit('setP2POffers', offers)
    } catch (err) {
      console.log('fetchP2POffers', err)
    }
  },
  async fetchP2PDeals ({ rootState, commit, dispatch }) {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${rootState.backEndAddress}/autoexchange/deals`,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      if (data.status !== 200) {
        throw Error('P2P DEALS! Something went wrong')
      }
      const dealsMap = {}
      data.payload.forEach(i => {
        const operationId = i.ae_deal.operation_id
        dealsMap[operationId] = new DealModel({
          ...i.ae_deal_ext,
          address_hashes: i.ae_deal_ext.addresses,
          ...i.ae_deal,
          addresses: i.addresses,
          buy_product_details: i.buy_product_details,
          buy_requisites: i.buy_requisites,
          sell_product_details: i.sell_product_details,
          sell_requisites: i.sell_requisites
        }, i)
      })
      commit('setP2PDealsMap', dealsMap)
      return true
    } catch (err) {
      console.log('fetchP2PDeals', err)
    }
  },
  async fetchP2PDealStatuses ({ rootState, commit }){
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${rootState.backEndAddress}/admin/ae-deal-status-rules`,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        }
      })
      if (data.status !== 200) {
        throw Error('fetchP2PDealStatuses! Something went wrong')
      }
      commit('setDealStatusRules', data.payload)
      return data.payload
    } catch (err) {
      console.log('fetchP2PDealsStatuses', err)
    }
  },
  async fetchP2PUserOffers ({ rootState, commit }) {
    try {
      // const { data } = await axios({
      //   method: 'GET',
      //   url: `${rootState.backEndAddress}/p2p/user-offers`,
      //   headers: {
      //     'Content-Type': 'application/json; charset=utf-8',
      //   },
      // })
      const { data } = await axios.get(`${rootState.backEndAddress}/autoexchange/user-offers`, {
        params: {
          cancelled: false
        }
      })
      if (data.status !== 200) {
        throw Error('P2P USER OFFERS! Something went wrong')
      }
      const orders = data.payload.filter(i => i.operation_type !== AE_OPERATION_TYPE_NAMES.AUTOEXCHANGE).map(P2POfferModel)
      commit('setP2PUserOrders', orders)
      return data.payload
    } catch (err) {
      console.log('fetchP2PUserOffers', err)
    }
  },
  async fetchP2PTypeDividers ({ rootState, commit }) {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${rootState.backEndAddress}/admin/p2p-security`
      })
      if (data?.status === 200) {
        commit('setP2PTypeDividers', data.payload)
      }
    } catch (err) {
      console.log({ err })
    }
  },
  async fetchP2PDealRequisites ({ rootState, commit }, { operationId, paymentType }) {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${rootState.backEndAddress}/p2p/requisites/${operationId}`,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        params: {
          paymentType
        }
      })
      // const { data } = await axios.get(`http://localhost:8000/proxy/p2p/requisites/${operationId}`, {
      //   params: {
      //     paymentType
      //   }
      // })
      if (data.status !== 200) {
        throw Error('fetchP2PDealRequisites! Something went wrong')
      }
      commit('setP2PDealRequisites', data.payload)
    } catch (err) {
      console.log('fetchP2PDealRequisites', err)
    }
  },
  addP2PRequest ({ rootState }, data) {
    return axios({
      method: 'POST',
      url: `${rootState.backEndAddress}/autoexchange/add`,
      data,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  },
  acceptRejectAction ({ rootState }, { data, action }) {
    const actionUrlMap = {
      [AutoexchangeSignatures.action.acceptP2PMatch]: 'accept-match',
      [AutoexchangeSignatures.action.rejectP2PMatch]: 'reject-match',
    }
    return axios({
      method: 'POST',
      url: `${rootState.backEndAddress}/p2p/${actionUrlMap[action]}`,
      data,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  },
  sendP2PDealAddress ({ rootState }, data) {
    return axios({
      method: 'POST',
      url: `${rootState.backEndAddress}/autoexchange/deal-address`,
      data,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  },
  sendP2PDealDocument ({ rootState }, data) {
    return axios({
      method: 'POST',
      url: `${rootState.backEndAddress}/autoexchange/add-document`,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  acceptP2PDealDocument ({ rootState }, data) {
    return axios({
      method: 'POST',
      url: `${rootState.backEndAddress}/autoexchange/accept-document`,
      data,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  },
  sendP2PDealStatus ({ rootState }, data) {
    return axios({
      method: 'POST',
      url: `${rootState.backEndAddress}/autoexchange/deal-status`,
      data,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  },
  async acceptPaymentType ({ rootState }, { payment_type, offer_operation_id, sell_offer_operation_id, key }) {
    const data = {
      offer_operation_id,
      payment_type,
      sell_offer_operation_id
    }
    const signedData = new P2PDealPaymentTypeAccept(data).getSignedRequest(key, rootState.UserName)
    return axios({
      method: 'POST',
      url: `${rootState.backEndAddress}/autoexchange/accept-payment-type`,
      data: signedData,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  },
  async updateP2PDealStatuses ({ rootState, dispatch, commit, state }, { statuses, offer_operation_id, key, sell_offer_operation_id }) {
    commit('SetLoaderFlag', true)
    try {
      const promises = statuses.map(status => {
        const data = {
          status,
          offer_operation_id,
          rule_type: state.p2pRuleType,
          sell_offer_operation_id
        }
        const signedData = new P2PDealStatus(data).getSignedRequest(key, rootState.UserName)
        return dispatch('sendP2PDealStatus', signedData)
      })
      const results = await Promise.allSettled(promises)
      console.log('updateP2PDealStatuses', { results })
      if (results?.some(result => result.value?.data?.status !== 200)) {
        throw new Error('updateP2PDealStatuses! Something went wrong')
      }
      return true
    } catch {
      notify({
        type: 'error',
        title: window.$t('error_title'),
        text: window.$t('something_went_wrong')
      })
      return false
    }
    finally {
      commit('setShowGlobalConfirm', { value: false })
      commit('SetLoaderFlag', false)
    }
  },
  async acceptP2PDealAddress ({ rootState }, data) {
    return axios({
      method: 'POST',
      url: `${rootState.backEndAddress}/autoexchange/accept-address`,
      data,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  },
  async downloadP2PDocument ({ rootState }, { docHash }) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${rootState.backEndAddress}/autoexchange/get-document/${docHash}`,
        responseType: 'blob',
      })
      const fileName = getFileName(response.headers['content-disposition'])
      const urlBlob = window.URL.createObjectURL(new Blob([response.data]))
      downloadFile(urlBlob, fileName)
      console.log({ response, urlBlob })
    } catch (error) {

    }
  },
  async sendFileByHash ({ rootState }, { hash, data }){
    return axios({
      method: 'POST',
      url: `${rootState.backEndAddress}/autoexchange/add-file/${hash}`,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  parseDealFromWS ({ commit, state }, data) {
    const { deal_with_ext, address, properties } = data,
      { ae_deal, ae_deal_ext } = deal_with_ext,
      oldDeal = state.p2pDealsMap[ae_deal.operation_id],
      newDeal = new DealModel({
        ...ae_deal_ext,
        address_hashes: ae_deal_ext.addresses,
        ...ae_deal,
      }, { ...oldDeal.orig, ...deal_with_ext })
    Object.keys(oldDeal).forEach(prop => {
      if (prop === 'addresses') {
        newDeal[prop] = oldDeal[prop]
      } else if(prop !== 'arbitration') {
        newDeal[prop] = newDeal[prop] || oldDeal[prop]
      }
    })
    if (address && !newDeal.accepted_address_hash) {
      newDeal.addresses.push(new P2PDealAddressModel(address))
    }
    commit('updateP2PDeal', newDeal)
    commit('setCurrentDeal', newDeal)
    properties.forEach(prop => {
      const additionalInfo = prop ? `: ${getAdditionalDealInfoFromWs(prop, newDeal)}` : ''
      notify({
        title: 'P2P',
        text: `Обновление по сделке ${newDeal.operation_id}${additionalInfo}`,
        type: 'success'
      })
    })
  },
  newP2PDeal ({ commit }, data) {
    const { ae_deal_with_ext_and_reqs: i } = data,
      newDeal = new DealModel({
        ...i.ae_deal_ext,
        address_hashes: i.ae_deal_ext.addresses,
        ...i.ae_deal,
        addresses: i.addresses,
        buy_product_details: i.buy_product_details,
        buy_requisites: i.buy_requisites,
        sell_product_details: i.sell_product_details,
        sell_requisites: i.sell_requisites
      }, i)
    commit('updateP2PDeal', newDeal)
    notify({
      title: 'P2P',
      text: 'Новая сделка',
      type: 'success'
    })
  },
  updateP2PGlassObjectAction ({ commit, state, getters }, { data }) {
    const { mktu: mktuNumbersFilters } = state.offersFilters
    if (mktuNumbersFilters?.length) {
      const { mktu_numbers: mktuFromOffer } = data
      if (!isMKTUNumbersMatch({ mktu_numbers: mktuNumbersFilters }, { mktu_numbers: mktuFromOffer })) return
    }
    const offer = P2POfferModel(data)
    commit('updateP2POffers', offer)
  }
}

const getters = {
  getDealStatusRulesBuyType: (state) => {
    return state.dealStatusRules?.reduce((acc, i) => {
      // if (!acc[i.rule_type]) acc[i.rule_type] = { BUY: [], SELL: [], OTHER: [] }
      // acc[i.rule_type][!i.user_turn ? 'OTHER' : i.user_turn].push(i)
      if (!acc[i.rule_type]) acc[i.rule_type] = []
      acc[i.rule_type].push(i)
      return acc
    }, {}) || []
  },
  getRuleTypes: (state, getters) => {
    return Object.keys(getters.getDealStatusRulesBuyType)
  },
  getOwnedCurrencies: (state, rootState) => operationType => {

  },
  getWantedCurrencies: (state, rootState) => operationType => {},
  getCurrentP2PPair: (state, rootState, getters, rootGetters) => {
    return rootGetters.getPairByCurrencies(state.ownedCurrency, state.wantedCurrency, state.p2pOperationType.value)
  },
  getP2PTypeDividersByType: state => state.p2pTypeDividers.reduce((acc, item) => {
    acc[item.type] = item.percent
    return acc
  }, {}),
  getP2PAssetObj: state => {
    return state.assetOffers.reduce((acc, offer) => {
      if (!acc[offer.firstCurrencyShort]) acc[offer.firstCurrencyShort] = []
      acc[offer.firstCurrencyShort].push(offer)
      return acc
    }, {})
  },
  getP2PAssetMatchedOrders: (state, getters) => {
    return makeP2PAssetDeals(getters.getP2PAssetObj[state.wantedCurrency])
  },
  getP2PAssetOffersByType: (state, getters) => {
    const { BUY, SELL } = SELECTOR_VALUE_NAMES
    const offersByType = { [BUY]: [], [SELL]: [] }
    getters.getP2PAssetMatchedOrders.forEach(offer => {
      const offerType = offer.type === FORM_TYPE_NAMES.INTERNAL
        ? SELL
        : BUY
      if (!offersByType[offerType]) offersByType[offerType] = []
      offersByType[offerType].push(offer)
    })
    return offersByType
  },
  getP2PAssetOffers: (state, getters) => getters.getP2PAssetOffersByType[state.p2pOfferType],
  getP2PSettingsCurrencies: state => Object.keys(state.p2pSettingsByCurrency),
  p2pDeals: state => Object.values(state.p2pDealsMap),
  getExtendedOpenedP2PDeals: (state, getters) => getters.p2pDeals.map(i => state.p2pExtendedDealsMap[i.offer_operation_id]),
  glassP2PObject: (state) => {
    const result = {}
    state.p2pOffers.forEach((offerItem) => {
      const offer = P2POfferModel(offerItem)
      const pairName = offer._rateCurrency

      if (!result[pairName]) result[pairName] = []
      result[pairName].push(offer)
    })
    return result
  },
  glassP2PMatchedOrders: (state, getters, rootState, rootGetters) => {
    const pairName = getters.getCurrentP2PPair?.pair
    const orders = getters.glassP2PObject?.[pairName] || []
    const decimalPlacesFa = Number(rootGetters.getProcessCurrencyObj('P2P_EXCHANGE', rootState.tokensNameMap[orders[0]?.first_currency]?.code)?.truncate_precision) || 2
    const decimalPlacesSa = Number(rootGetters.getProcessCurrencyObj('P2P_EXCHANGE', rootState.tokensNameMap[orders[0]?.second_currency]?.code)?.truncate_precision) || 2
    // if (state.p2pOperationType.value === AE_OPERATION_TYPE_NAMES.AUTOEXCHANGE_CASH) return makeP2PDeals(orders, decimalPlacesFa, decimalPlacesSa)
    return makeP2PBillDeals(orders, decimalPlacesFa, decimalPlacesSa)
  },
  getP2POffersByType: (state, getters) => {
    const { BUY, SELL } = SELECTOR_VALUE_NAMES
    const offersByType = { [BUY]: {}, [SELL]: {} }
    getters.glassP2PMatchedOrders.forEach(offer => {
      const { exchange_rate } = offer
      const offerType = offer.type === 'sell'
        ? SELL
        : BUY
      const pairName = offer._rateCurrency
      // if (!offersByType[offerType][pairName]) offersByType[offerType][pairName] = {}
      // if (!offersByType[offerType][pairName]?.[exchange_rate])
      //   offersByType[offerType][pairName][exchange_rate] = []
      // offersByType[offerType][pairName][exchange_rate].push(offer)
      if (!offersByType[offerType][pairName]) offersByType[offerType][pairName] = []
      offersByType[offerType][pairName].push(offer)
    })
    return offersByType
  },
  getP2PUserFiatOrders: (state) => state.userOrders,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
