import Web3 from 'web3'

export default class {
  constructor (provider) {
    if (typeof provider !== 'undefined' && provider.isMetaMask) {
      const web3Instance = new Web3(provider)
      this.provider = provider
      this.ethNetwork = web3Instance.eth
      this.utils = web3Instance.utils
      this.web3 = web3Instance
    } else {
      throw new Error('Please install MetaMask')
    }
  }

  async connectToMetaMask () {
    return new Promise(async (resolve, reject) => {
      try {
        const accountsCb = accounts => {
          this.provider.removeListener('accountsChanged', accountsCb)
          resolve(accounts)
        }
        this.provider.on('accountsChanged', accountsCb)
        if (this.provider.isConnected() && !!this.selectedAddress) resolve()
        this.provider.request({
          method: 'eth_requestAccounts'
        })
      } catch (e) {
        reject()
      }
    })
  }

  get selectedAddress () {
    const { currentProvider } = this.ethNetwork
    return currentProvider?.selectedAddress
  }
}
