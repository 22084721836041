import { useStore } from 'vuex'

export default function (dealObj, statuses) {
  const store = useStore()
  const updateStatusesHandler = (userData) => {
    return store.dispatch('updateP2PDealStatuses', {
      statuses: statuses.value,
      key: userData.key,
      offer_operation_id: dealObj.offer_operation_id,
      sell_offer_operation_id: dealObj.sell_offer_operation_id,
      deal: dealObj.orig
    })
  }
  return {
    updateStatusesHandler
  }
}
