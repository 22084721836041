import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { deepCopy } from '@/utils/common'

export default function (mktuNumbers, localMode = false) {
  const mktuClassObject = !localMode ? computed({
    get () { return store.state.P2P.mktuClassObject },
    set (data) { store.commit('setMKTUClassObject', data) }
  }) : ref()
  const store = useStore()
  let initMKTUObject = {}
  if (mktuNumbers?.length) {
    initMKTUObject = (mktuNumbers || []).reduce((acc, number) => {
      if (number.length < 3) {
        acc[number] = store.getters.getMKTUCodesByClass(number).map(i => i.number)
      } else {
        const className = number.slice(0, number.length === 5 ? 1 : 2)
        if (!acc[className]) acc[className] = []
        acc[className].push(number)
      }
      return acc
    }, {})
  } else {
    initMKTUObject = store.getters.getMKTUClasses.reduce((acc, className) => {
      acc[className] = []
      return acc
    }, {})
  }
  mktuClassObject.value = deepCopy(initMKTUObject)
  const calculateOffersFilters = () => store.dispatch('calculateOffersFilters')
  if (mktuNumbers && !localMode) calculateOffersFilters()
  return { mktuClassObject, calculateOffersFilters }
}
