// import Vue from 'vue'

import { app as Vue } from '@/entry-files/lk'

// import Vuex from 'vuex'
import { createStore } from 'vuex'
import Decimal from 'decimal.js'

import AuthForm2Client from '@/models/AuthForm2Client.js'
import SRP6CryptoParams from '@/models/SRP6CryptoParams.js'

import Auth from '@/store/Auth.js'
import Transactions from '@/store/Transactions.js'
import Wallets from '@/store/Wallets.js'
import Transfer from '@/store/Transfer.js'
import Input from '@/store/Input.js'
import ProcessCurrency from '@/store/ProcessCurrency.js'
import Commission from '@/store/Commission.js'
import Exchange from '@/store/Exchange.js'
import Session from '@/store/Session.js'
import Withdrawal from '@/store/Withdrawal.js'
import Referral from '@/store/Referral.js'
import kyc from '@/store/KYC.js'
import MailSend from '@/store/MailSend.js'
import Socket from '@/store/SocketSubscribe.js'
import Lang from '@/store/lang.js'
import Token from '@/store/tokenSale.js'
import Autoexchange from '@/store/Autoexchange'
import Admin from '@/store/Admin'
import { TOKEN_TYPE_NAMES, WINDOW_MODE_NAMES } from '@/constants/common'
import Cash from '@/store/Cash'
import CashService from '@/store/CashService'
import { NativeBiometric } from 'capacitor-native-biometric'
import P2P from '@/store/P2P'

const getTokenObjectByCode = (tokens, code) => {
  const result = {}
  tokens.forEach(t => { if (t.token_type === code) result[t.code] = t.code })
  return result
}

const store = createStore({
  modules: {
    Auth,
    Transactions,
    Wallets,
    Transfer,
    Input,
    ProcessCurrency,
    Commission,
    Exchange,
    Session,
    Withdrawal,
    Referral,
    kyc,
    MailSend,
    Socket,
    Lang,
    Token,
    AuthForm2Client: AuthForm2Client,
    SRP6CryptoParams: SRP6CryptoParams,
    Autoexchange,
    Admin,
    Cash,
    CashService,
    P2P
  },
  state: () => ({
    tokens: [],
    tokensCodeMap: {},
    tokensNameMap: {},
    biometricData: null,
    showUseBiometricPopup: false,
    fbToken: '',
    Authorized: false,
    selectedLang: 'ru',
    showMobileMenu: false,
    UserName: '' || localStorage.getItem('user_id'), // Имя пользователя
    WalletBalance: [], // Данные по кошелькам и счетам с балансами
    Balance: {}, // Баланс пользователя по валютам со всех счетов
    // V1
    InRequest: [], // Входящие завяки на обмен
    OutRequest: [], // Исходящие заявки на обмен
    InHistoryRequest: [], // История входящих заявок на обмен
    OutHistoryRequest: [], // История исходящих заявок на обмен

    Payments: [], // Платежи пользователя
    MainWalletInfo: {}, // Информация с номером главного кошелька и главного счета
    backEndAddressNotLink: window.location.origin,
    backEndAddress: `${window.location.origin}/backend-cryptoenter`, // Адрес сервера для отправки запросов
    rdbmsServiceAddress: `${window.location.origin}/rdbms`, // Адрес сервера с постгрес для отправки запросов
    // backEndAddressNotLink: process.env.VUE_APP_API_URL,
    // backEndAddress: `${process.env.VUE_APP_API_URL}/backend-cryptoenter`, // Адрес сервера для отправки запросов
    // rdbmsServiceAddress: `${process.env.VUE_APP_API_URL}/rdbms`, // Адрес сервера с постгрес для отправки запросов
    NewInRequestHistory: [],
    Round: 'round1',
    // map min(currencyName1,currencyName2)+max(currencyName1,currencyName2) -> bool
    // bool обозначает используется ли прямой или обратный лексикографический порядок при записи валютной пары
    // т.е. true -> BTC/ETH
    // false -> ETH/BTC
    /** Флаги загрузки данных **/
    loadTransactionsFlag: false, // TODO: Добавить сброс в дефаулт перед отправкой запроса
    loadOffersFlag: false, // TODO: Добавить сброс в дефаулт перед отправкой запроса
    loadWalletsFlag: false, // TODO: Добавить сброс в дефаулт перед отправкой запроса
    loadWalletsAndAccountFlag: false,
    LOADER_FLAG: false,
    MODAL_FLAG: false,
    JOIN_MODAL_FLAG: false,
    VIDEO_FOR_MODAL: '',
    /**
     * Флаги событий
     */
    NewPaymentCount: 0,
    NewInRequest: 0,
    NewOutRequest: 0,
    showRightPanelBadge: false,
    /**
     * Динамическое опевещение для заголовка
     */
    TitleAlert: '',
    stompClient: '',
    CurrencyList: [],
    windowSizeMode: WINDOW_MODE_NAMES.MOBILE,
    // Глобальный попап подтверждения операций
    showGlobalConfirm: false,
    globalConfirmAcceptCb: () => '',
    globalConfirmCancelCb: () => '',
    isProductionHostname: ['cryptoenter.com', 'cryptoenter.ru'].includes(window.location.hostname),
    rightPanelExpandedBlocks: {},
    visibleSupportPopup: false,
    showMobileSettingsState: false,
    currentPairSubscription: null,
    showActionsMenu: null
  }),
  getters: {
    availableUserCurrencies: (state, getters) => Object.keys(getters.getMainWalletBalance),
    isMobile: state => state.windowSizeMode === WINDOW_MODE_NAMES.MOBILE,
    isMobileOrTablet: state => [WINDOW_MODE_NAMES.MOBILE, WINDOW_MODE_NAMES.TABLET].includes(state.windowSizeMode),
    isDesktop: state => {
      const {
        DESKTOP,
        DESKTOP_1280,
        DESKTOP_1920
      } = WINDOW_MODE_NAMES
      return [DESKTOP, DESKTOP_1280, DESKTOP_1920].includes(state.windowSizeMode)
    },
    GetAuthorized: state => state.Authorized,
    GetRound: state => state.Round,
    GetUserName: state => state.UserName,
    GetBalance: state => state.Balance,
    GetWalletBalance: state => state.WalletBalance,
    GetInRequest: state => state.InRequest,
    GetOutRequest: state => state.OutRequest,
    GetInHistoryRequest: state => state.InHistoryRequest,
    GetOutHistoryRequest: state => state.OutHistoryRequest,
    GetPayments: state => state.Payments,
    GetWalletInfo: state => state.MainWalletInfo,
    GetNewInRequestHistory: state => state.NewInRequestHistory,
    IsProductionEnv: state => {
      return ['cryptoenter.com', 'cryptoenter.ru'].includes(window.location.host)
    },
    IsBalanceEnough: (state, getters) => (amount, currency) => {
      const currencyCode = state.tokensNameMap[currency]?.code
      const balance = getters.getMainWalletBalance[currencyCode] ?? 0
      return new Decimal(balance).gte(new Decimal(amount))
    },
    IsBalanceEnoughWithCommission: (state, getters) => (amount, currency, commAmount) => {
      const currencyCode = state.tokensNameMap[currency]?.code
      const balance = getters.getMainWalletBalance[currencyCode] ?? 0
      if (currency === 'lion') {
        return new Decimal(balance).gte(new Decimal(amount).plus(new Decimal(commAmount)))
      } else {
        const lionBalance = getters.getMainWalletBalance['LION'] ?? 0
        return new Decimal(balance).gte(new Decimal(amount)) && new Decimal(lionBalance).gte(new Decimal(commAmount))
      }
    },
    isBalanceEnoughWithCommission: (state, getters, rootState) => (amount, currency, commAmount) => {
      const balance = getters.getMainWalletBalance[currency] ?? -1
      if (currency === rootState.tokensNameMap['lion']?.code) {
        return new Decimal(balance).gte(new Decimal(amount).plus(new Decimal(commAmount || 0)))
      } else {
        const lionBalance = getters.getMainWalletBalance['LION'] ?? 0
        return new Decimal(balance).gte(new Decimal(amount || 0)) && new Decimal(lionBalance).gte(new Decimal(commAmount || 0))
      }
    },
    // Флаги загрузки данных
    GetTransactionsFlags: state => state.loadTransactionsFlag,
    GetOffersFlags: state => state.loadOffersFlag,
    GetWalletsFlags: state => state.loadWalletsFlag,
    GetWalletsAndAccountFlag: state => state.loadWalletsAndAccountFlag,
    GetLoaderFlag: state => state.LOADER_FLAG,
    GetVideoForModal: state => state.VIDEO_FOR_MODAL,
    GetModalFlag: state => state.MODAL_FLAG,
    GetJoinModalFlag: state => state.JOIN_MODAL_FLAG,
    // Флаги событий
    GetNewPaymentCount: state => state.NewPaymentCount,
    GetNewInRequest: state => state.NewInRequest,
    GetNewOutRequest: state => state.NewOutRequest,
    // Заголовок
    GetTitle: state => state.TitleAlert,
    GetCurrencyList: state => state.CurrencyList,
    showSupportPopup: state => state.visibleSupportPopup,
    // валюты(токены)
    listCurrencyName: state => state.tokens.map(i => i.code),
    ethCurrencyNames: state => {
      const result = {}
      state.tokens.forEach(t => { if (['ETH', 'LION', 'USDT'].includes(t.code)) result[t.code] = t.code })
      return result
    },
    ethCurrencies: (state, getters) => Object.keys(getters.ethCurrencies),
    ethTokenCurrencies: (state, getters) => getters.ethCurrencies.filter(i => i !== 'ETH'),
    fiatCurrencyNames: state => getTokenObjectByCode(state.tokens, TOKEN_TYPE_NAMES.Fiat),
    fiatCurrencies: (state, getters) => Object.keys(getters.fiatCurrencyNames),
    fiatCashCurrencyNames: state => getTokenObjectByCode(state.tokens, TOKEN_TYPE_NAMES.FiatCash),
    fiatCashCurrencies: (state, getters) => Object.keys(getters.fiatCashCurrencyNames)
  },
  mutations: {
    setShowRightPanelBadge (state, val) {
      state.showRightPanelBadge = val || false
    },
    setTokens (state, tokens) {
      state.tokens = tokens
      tokens.forEach(token => {
        state.tokensCodeMap[token.code] = token
        state.tokensNameMap[token.name] = token
      })
    },
    setBiometricData (state, data) {
      state.biometricData = data || null
    },
    setShowUseBiometricPopup (state, val) {
      state.showUseBiometricPopup = val || false
    },
    setShowMobileSettingsState (state, val) {
      state.showMobileSettingsState = val
    },
    updateRightPanelExpandedBlocks: (state, { blockName, value }) => {
      state.rightPanelExpandedBlocks[blockName] = value
    },
    setShowMobileMenu: (state, value) => {
      state.showMobileMenu = value
    },
    setSelectedLang: (state, value) => {
      state.selectedLang = value
    },
    setWindowSizeMode: (state, value) => {
      state.windowSizeMode = value
    },
    SetAuthorized: (state, data) => {
      state.Authorized = data
    },
    SetRound: (state, data) => {
      state.Round = data
    },
    SetUserName: (state, userName) => {
      state.UserName = userName
    },
    SetBalance: (state, balance) => {
      state.Balance = balance
    },
    SetInRequest: (state, inRequest) => {
      state.InRequest = inRequest
    },
    SetOutRequest: (state, inRequest) => {
      state.OutRequest = inRequest
    },
    SetInHistoryRequest: (state, inRequest) => {
      state.InHistoryRequest = inRequest
    },
    SetOutHistoryRequest: (state, inRequest) => {
      state.OutHistoryRequest = inRequest
    },
    SetPayments: (state, payments) => {
      state.Payments = payments
    },
    SetWalletInfo: (state, setWalletInfo) => {
      state.MainWalletInfo = setWalletInfo
    },
    SetWalletBalance: (state, walletBalance) => {
      state.WalletBalance = walletBalance
    },
    // Установка флагов загрузки
    SetTransactionsFlags: (state, data) => {
      state.loadTransactionsFlag = data
    },
    SetOffersFlags: (state, data) => {
      state.loadOffersFlag = data
    },
    SetWalletsFlags: (state, data) => {
      state.loadWalletsFlag = data
    },
    SetWalletsAndAccountFlag: (state, data) => {
      state.loadWalletsAndAccountFlag = data
    },
    SetLoaderFlag: (state, data) => {
      state.LOADER_FLAG = data
    },
    SetVideoForModal: (state, data) => {
      state.VIDEO_FOR_MODAL = data
    },
    SetModalFlag: (state, data) => {
      state.MODAL_FLAG = data
    },
    SetJoinModalFlag: (state, data) => {
      state.JOIN_MODAL_FLAG = data
    },
    SetCurrencyList: (state, data) => {
      state.CurrencyList = data
    },
    // Мутации флагов событий
    ChangeNewPaymentCount: (state, data) => {
      state.NewPaymentCount = data ? state.NewPaymentCount += data : state.NewPaymentCount = data
      if (state.NewPaymentCount + state.NewInRequest/* + state.NewOutRequest */) {
        state.TitleAlert = `(${state.NewPaymentCount + state.NewInRequest/* + state.NewOutRequest */})`
        Vue.$StartChange()
      }
    },
    ChangeNewInRequest: (state, data) => {
      state.NewInRequest = data ? state.NewInRequest += data : state.NewInRequest = data
      if (state.NewPaymentCount + state.NewInRequest/* + state.NewOutRequest */) {
        state.TitleAlert = `(${state.NewPaymentCount + state.NewInRequest/* + state.NewOutRequest */})`
        Vue.$StartChange()
      }
    },
    ChangeNewOutRequest: (state, data) => {
      state.NewOutRequest = data ? state.NewOutRequest += data : state.NewOutRequest = data
      if (state.NewPaymentCount + state.NewInRequest/* + state.NewOutRequest */) {
        state.TitleAlert = `(${state.NewPaymentCount + state.NewInRequest/* + state.NewOutRequest */})`
        Vue.$StartChange()
      }
    },
    NewOffer: (state, newData) => {
      if (state.InRequest.length) {
        let flag = false
        for (let i = 0; i < state.InRequest.length; i++) {
          if (state.InRequest[i].user === newData.UserName) {
            state.InRequest[i].request.unshift(newData)
            flag = true
          }
        }
        if (!flag) {
          state.InRequest.unshift({})
          state.InRequest[0].request = []
          state.InRequest[0].user = newData.UserName
          state.InRequest[0].request.unshift(newData)
        }
      } else {
        state.InRequest.unshift({})
        state.InRequest[0].request = []
        state.InRequest[0].user = newData.UserName
        state.InRequest[0].request.unshift(newData)
      }
    },
    NewOutRequest: (state, newData) => {
      if (state.OutRequest.length) {
        let flag = false
        for (let i = 0; i < state.OutRequest.length; i++) {
          if (state.OutRequest[i].user === newData.UserName) {
            state.OutRequest[i].request.unshift(newData)
            flag = true
          }
        }
        if (!flag) {
          state.OutRequest.unshift({})
          state.OutRequest[0].request = []
          state.OutRequest[0].user = newData.UserName
          state.OutRequest[0].request.unshift(newData)
        }
      } else {
        state.OutRequest.unshift({})
        state.OutRequest[0].request = []
        state.OutRequest[0].user = newData.UserName
        state.OutRequest[0].request.unshift(newData)
      }
    },
    CanceledRequest: (state, idRequest) => {
      for (let i = 0; i < state.InRequest.length; i++) {
        for (let j = 0; j < state.InRequest[i].request.length; j++) {
          if (state.InRequest[i].request[j].operationId === idRequest) {
            state.InRequest[i].request.splice(j, 1)
            break
          }
        }
        if (!state.InRequest[i].request.length) {
          state.InRequest.splice(i, 1)
          break
        }
      }
      for (let i = 0; i < state.OutRequest.length; i++) {
        for (let j = 0; j < state.OutRequest[i].request.length; j++) {
          if (state.OutRequest[i].request[j].operationId === idRequest) {
            state.OutRequest[i].request.splice(j, 1)
            break
          }
        }
        if (!state.OutRequest[i].request.length) {
          state.OutRequest.splice(i, 1)
          break
        }
      }
    },
    RejectedRequest: (state, idRequest) => {
      for (let i = 0; i < state.InRequest.length; i++) {
        for (let j = 0; j < state.InRequest[i].request.length; j++) {
          if (state.InRequest[i].request[j].operationId === idRequest) {
            state.InRequest[i].request[j].outUser = state.InRequest[i].user
            state.InRequest[i].request[j].status = 'rejected'
            state.NewInRequestHistory.push(state.InRequest[i].request[j])
            state.InRequest[i].request.splice(j, 1)
            break
          }
        }
        if (!state.InRequest[i].request.length) {
          state.InRequest.splice(i, 1)
          break
        }
      }
      for (let i = 0; i < state.OutRequest.length; i++) {
        for (let j = 0; j < state.OutRequest[i].request.length; j++) {
          if (state.OutRequest[i].request[j].operationId === idRequest) {
            state.OutRequest[i].request.splice(j, 1)
            break
          }
        }
        if (!state.OutRequest[i].request.length) {
          state.OutRequest.splice(i, 1)
          break
        }
      }
    },
    AcceptRequest: (state, idRequest) => {
      // console.log('idRequest ', idRequest)
      for (let i = 0; i < state.InRequest.length; i++) {
        for (let j = 0; j < state.InRequest[i].request.length; j++) {
          if (state.InRequest[i].request[j].operationId === idRequest) {
            state.InRequest[i].request[j].outUser = state.InRequest[i].user
            state.InRequest[i].request[j].status = 'accepted'
            state.NewInRequestHistory.push(state.InRequest[i].request[j])
            state.InRequest[i].request.splice(j, 1)
            break
          }
        }
        if (!state.InRequest[i].request.length) {
          state.InRequest.splice(i, 1)
          break
        }
      }
      for (let i = 0; i < state.OutRequest.length; i++) {
        for (let j = 0; j < state.OutRequest[i].request.length; j++) {
          if (state.OutRequest[i].request[j].operationId === idRequest) {
            state.OutRequest[i].request.splice(j, 1)
            break
          }
        }
        if (!state.OutRequest[i].request.length) {
          state.OutRequest.splice(i, 1)
          break
        }
      }
    },
    // Мутация оповещения заголовка
    ChangeTitleAlert: (state, alert) => {
      state.TitleAlert = alert
    },
    // Глобальное окно подтверждения операции
    setShowGlobalConfirm (state, { value, acceptCb, cancelCb }) {
      state.showGlobalConfirm = value
      if (value) {
        state.globalConfirmAcceptCb = acceptCb
        state.globalConfirmCancelCb = cancelCb
      } else {
        state.globalConfirmAcceptCb = () => ''
        state.globalConfirmCancelCb = () => ''
      }
    },
    setVisibleSupportPopup (state, value) {
      state.visibleSupportPopup = value
    },
    setStompClient (state, val) {
      state.stompClient = val
    },
    setCurrentPairSubscription (state, val) {
      state.currentPairSubscription = val
    },
    setShowActionsMenu (state, val) {
      state.showActionsMenu = val ? 0 : null
    }
  },
  actions: {
    async loadInitData ({ dispatch }) {
      return Promise.all([
        dispatch('getTokens'),
        dispatch('fetchUnitsOfMeasurements'),
        dispatch('fetchMKTUProducts'),
        dispatch('getAllCommissions'),
        dispatch('getLionCommission')
      ])
    },
    openRightPanelBlock ({ commit }, blockName) {
      commit('updateRightPanelExpandedBlocks', { blockName, value: true })
    },
    closeRightPanelBlock ({ commit }, blockName) {
      commit('updateRightPanelExpandedBlocks', { blockName, value: false })
    },
    async checkShowBiometric (context, { reason, title }) {
      try {
        const result = await NativeBiometric.isAvailable()
        if (result.isAvailable) {
          const verified = await NativeBiometric.verifyIdentity({
            reason,
            title
          })
            .then(() => true)
            .catch(() => false);

          if (!verified) return;

          const credentials = await NativeBiometric.getCredentials({
            server: "develop.cryptoenter.com",
          });
          // в username хранится pem
          return {
            key: credentials.username,
            password: credentials.password
          }
        }
      } catch (err) {
        console.log(err)
        return {}
      }
    }
  }
})
export default store
